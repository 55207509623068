import {
  AppStateExperience,
  AtlasAddressCollection,
  AtlasFirebaseSchema,
  IWaandrUser,
  AtlasAdminReview,
  ExperienceDraft,
} from "@iwaandr/iwaandr-sdk";
import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Avatar,
  Button,
  ButtonGroup,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Paper,
  Skeleton,
  styled,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import useAuth from "contexts/auth/hooks";
import { PrivateAtlasApi } from "features/atlas/api/private";
import { PrivateExperienceApi } from "features/experiences/private";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import CheckIcon from "@mui/icons-material/Check";
import RefreshIcon from "@mui/icons-material/Refresh";
import { PublicProfileApi } from "features/account/api/public";
import { User } from "firebase/auth";
import { PrivateAtlasQaApi } from "features/qa/atlas/private";
import RequestChanges from "components/qa/RequestChanges/requestChanges";
import { PrivateProfileApi } from "features/account/api/private";
import { PrivateInternalApi } from "features/internal/api/private";

interface AtlasProps {}

const cardHeight = 125;
const cardWith = 350;

const AtlasImage = styled("img")({
  borderRadius: 10,
});
const CommentsAccordion = styled(Accordion)({
  marginTop: 20,
});

const ExperienceCard = styled(Card)((theme) => {
  return {
    height: cardHeight,
    width: cardWith,
    transition: "all .2s ease-in-out",
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.075)",
    },
  };
});

const Atlas: React.FunctionComponent<AtlasProps> = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [atlas, setAtlas] = React.useState<AtlasFirebaseSchema | undefined>();
  const [approvalError, setApprovalError] = React.useState<
    string | undefined
  >();
  const [atlasError, setAtlasError] = React.useState<string | undefined>();
  const [experienceError, setExperienceError] = React.useState<
    string | undefined
  >();
  const { authUser } = useAuth();
  const [authorInfoViewed, setAuthorInfoViewed] = React.useState(false);
  const [authorEmail, setAuthorEmail] = React.useState<string>();
  const [toggleDialog, setToggleDialog] = React.useState(false);
  const [isInfoViewed, setIsInfoViewed] = React.useState(false);
  const [isApproving, setIsApproving] = React.useState(false);
  const [areDetailsViewed, setAreDetailsViewed] = React.useState(false);
  const [commentsList, setCommentsList] = React.useState<
    Array<{
      [key: string]: AtlasAdminReview;
    }>
  >([]);
  const [experiences, setExperiences] = React.useState<{
    [key: string]: AppStateExperience | ExperienceDraft | undefined;
  }>({});
  const [currentTab, setTab] = React.useState(1);
  const [author, setAuthor] = React.useState<IWaandrUser | undefined>();
  const [actionState, setActionState] = React.useState<"Approve" | undefined>();
  React.useEffect(() => {
    if (params.atlasId === undefined || authUser === undefined) return;
    PrivateInternalApi.getAtlasQaComments(params.atlasId, authUser).then(
      (comments) => {
        return setCommentsList(comments);
      }
    );

    PrivateAtlasApi.getAtlas(authUser, params.atlasId)
      .then((atlas) => {
        setAtlas(atlas);
        PrivateProfileApi.getIWaandrUser(authUser, atlas.ownerId)
          .then((atlasAuthor) => {
            atlasAuthor && atlasAuthor.email
              ? setAuthorEmail(atlasAuthor?.email)
              : console.log("atlas author not defined");
          })
          .catch(() => setAtlasError("Failed to fetch author"));
        PublicProfileApi.getPublicProfileInfo(atlas.ownerId)
          .then((atlasAuthor) => {
            console.log("got user", atlasAuthor);
            setAuthor(atlasAuthor);
          })
          .catch(() => setAtlasError("Failed to fetch author"));
      })
      .catch(() => setAtlasError("Failed to fetch atlas"));
  }, []);

  const getExperiences = (
    ids: string[],
    definedUser: User,
    creatorId: string
  ) => {
    const promises: Promise<AppStateExperience | ExperienceDraft>[] = [];

    ids.forEach((id) => {
      if (id) {
        promises.push(
          PrivateExperienceApi.getExperience(definedUser, id, creatorId)
        );
      }
    });

    Promise.all(promises)
      .then((allExp) => {
        const stateObj: {
          [key: string]: AppStateExperience | ExperienceDraft;
        } = {};

        allExp.forEach((exp) => {
          if (exp && exp.id) {
            stateObj[exp.id] = exp;
          } else {
            setExperienceError("One or more experiences could not be found");
          }
        });

        setExperiences(stateObj);
      })
      .catch(() =>
        setExperienceError("One or more experiences could not be found")
      );
  };

  React.useEffect(() => {
    if (atlas === undefined || authUser === undefined) return;
    const expIds = Object.keys(atlas.experiences).flatMap((day) => {
      if (day === null) return [];

      return atlas.experiences[Number(day)];
    });

    getExperiences(expIds, authUser, atlas.ownerId);
  }, [atlas, authUser]);

  if (atlas === undefined && atlasError === undefined) {
    <Grid
      container
      sx={{ height: "100%" }}
      justifyContent="center"
      alignContent="center"
    >
      <CircularProgress />
    </Grid>;
  }

  const loadedExperiencesCount = Object.keys(experiences).length;
  const atlasExperienceCount = Object.keys(atlas?.experiences ?? {})
    .flatMap((day) => atlas?.experiences[Number(day)])
    .filter((exp) => exp !== null).length;

  return (
    <Grid
      container
      sx={{ height: "100%", mt: 2 }}
      justifyContent="center"
      rowSpacing={3}
    >
      {atlasError ? (
        <Grid item xs={12}>
          <Alert severity="error">{atlasError}</Alert>
        </Grid>
      ) : null}
      <Grid item xs={12} container justifyContent="center">
        <AtlasImage
          src={atlas?.imageUrl}
          alt={atlas?.title}
          height={320}
          width={450}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Typography variant="subtitle1" color="primary" textAlign="center">
            <i>You are reviewing...</i>
          </Typography>
        </Grid>
        <Grid container justifyContent="center">
          <Typography variant="h3">{atlas?.title}</Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid container justifyContent="center" rowSpacing={3}>
          <Grid item xs={8} container>
            <Grid item xs={10}>
              <Tabs
                value={currentTab}
                onChange={(_, val) => setTab(val)}
                textColor="inherit"
              >
                {Object.keys(atlas?.experiences ?? {}).map((key) => {
                  if (key === null || key === "0") return null;

                  return (
                    <Tab label={`Day ${key}`} key={key} value={Number(key)} />
                  );
                })}
              </Tabs>
            </Grid>

            <Grid
              item
              xs={2}
              container
              justifyContent="flex-end"
              alignItems="center"
            >
              <Tooltip title={<Typography>Refresh experiences</Typography>}>
                <IconButton
                  onClick={() => {
                    if (authUser === undefined || atlas === undefined) return;

                    const expCopy: {
                      [key: string]:
                        | AppStateExperience
                        | ExperienceDraft
                        | undefined;
                    } = Object.assign({}, experiences);
                    const keys = Object.keys(expCopy);
                    keys.forEach((key) => (expCopy[key] = undefined));
                    setExperiences(expCopy);

                    getExperiences(keys, authUser, atlas.ownerId);
                  }}
                >
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid item xs={8} container spacing={4}>
            {experienceError === undefined ? (
              atlas?.experiences[Number(currentTab)].map((expId) => {
                const exp = experiences[expId];
                if (exp === undefined) {
                  return (
                    <Skeleton
                      variant="rectangular"
                      width={cardWith}
                      height={cardHeight}
                    />
                  );
                }
                return (
                  <Grid item key={expId}>
                    <ExperienceCard
                      onClick={() => {
                        navigate(`/app/experience/${expId}`);
                      }}
                    >
                      <Grid container height={"100%"} width="100%">
                        <Grid
                          item
                          xs={6}
                          sx={{
                            backgroundImage: `url(${exp.picture})`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                          }}
                        />
                        <Grid container item xs={6} alignContent="flex-start">
                          <Grid
                            item
                            xs={12}
                            sx={{
                              backgroundColor: (theme) => {
                                if (exp.hasBeenApproved) {
                                  return theme.palette.success.light;
                                }
                                return theme.palette.warning.light;
                              },
                              height: 25,
                            }}
                          />
                          <Grid item xs={12} container p={1}>
                            <Grid item xs={10}>
                              {" "}
                              <Typography noWrap>{exp.title}</Typography>
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              container
                              justifyContent="flex-end"
                            >
                              {exp.hasBeenApproved ? (
                                <CheckIcon />
                              ) : (
                                <PriorityHighIcon />
                              )}
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            container
                            height="50%"
                            justifyContent="flex-end"
                            alignContent="flex-end"
                          >
                            <Button
                              color={
                                exp.hasBeenApproved ? "secondary" : "primary"
                              }
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              href={`/app/experience/${expId}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Review
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </ExperienceCard>
                  </Grid>
                );
              })
            ) : (
              <Grid item xs={12}>
                <Alert severity="error">{experienceError}</Alert>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <Grid item xs={8}>
            <hr />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={8}>
        <Accordion>
          <AccordionSummary
            onClick={() => {
              setAuthorInfoViewed(true);
            }}
          >
            <Grid container justifyContent="space-between">
              <Grid item xs={3}>
                <Typography variant="h4">Author Info</Typography>
              </Grid>
              <Grid
                item
                xs={3}
                container
                alignContent="center"
                justifyContent="flex-end"
              >
                <Tooltip
                  title={
                    <Typography fontSize={"1.1rem"}>
                      {`This section ${
                        authorInfoViewed ? "has" : "has not"
                      } been
                      viewed`}
                    </Typography>
                  }
                >
                  <Typography variant="h4">
                    {authorInfoViewed ? "✅" : "❌"}
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container rowSpacing={3} spacing={2}>
              <Grid item xs={6} container>
                <Grid item xs={3}>
                  <Avatar
                    src={author?.photoUrl}
                    sx={{ height: 75, width: 75 }}
                  />
                </Grid>
                <Grid item xs={9} container>
                  <Grid item xs={12}>
                    <Typography variant="h5">{author?.userName}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>{author?.bio}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} container rowSpacing={2}>
                <Grid item xs={12}>
                  <Typography borderBottom={"1px solid black"}>
                    About the Poster
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>{atlas?.aboutThePoster}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={8}>
        <Accordion>
          <AccordionSummary
            onClick={() => {
              setIsInfoViewed(true);
            }}
          >
            <Grid container justifyContent="space-between">
              <Grid item xs={3}>
                <Typography variant="h4">Atlas Info</Typography>
              </Grid>
              <Grid
                item
                xs={3}
                container
                alignContent="center"
                justifyContent="flex-end"
              >
                <Tooltip
                  title={
                    <Typography fontSize={"1.1rem"}>
                      {`This section ${isInfoViewed ? "has" : "has not"} been
                      viewed`}
                    </Typography>
                  }
                >
                  <Typography variant="h4">
                    {isInfoViewed ? "✅" : "❌"}
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container rowSpacing={3}>
              <Grid item xs={12} container>
                <Grid item xs={3}>
                  <Typography variant="h6">Locations:</Typography>
                </Grid>
                <Grid item xs={9} container alignItems="center">
                  <Typography>
                    {getLocations(
                      (atlas?.location ?? {}) as AtlasAddressCollection
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} container>
                <Grid item xs={3}>
                  <Typography variant="h6">Price:</Typography>
                </Grid>
                <Grid item xs={9} container alignItems="center">
                  <Typography>${atlas?.price}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} container>
                <Grid item xs={3}>
                  <Typography variant="h6">Type:</Typography>
                </Grid>
                <Grid item xs={9} container alignItems="center">
                  <Typography>{atlas?.forTravelerType}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} container>
                <Grid item xs={3}>
                  <Typography variant="h6">Duration:</Typography>
                </Grid>
                <Grid item xs={9} container alignItems="center">
                  <Typography>{atlas?.duration?.label}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} container>
                <Grid item xs={3}>
                  <Typography variant="h6">Total Experiences:</Typography>
                </Grid>
                <Grid item xs={9} container alignItems="center">
                  <Typography>{Object.keys(experiences).length}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} container>
                <Grid item xs={3}>
                  <Typography variant="h6">Atlas Summary:</Typography>
                </Grid>
                <Grid item xs={9} container alignItems="center">
                  <Typography>{atlas?.overview}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={8}>
        <Accordion>
          <AccordionSummary
            onClick={() => {
              setAreDetailsViewed(true);
            }}
          >
            <Grid container justifyContent="space-between">
              <Grid item xs={3}>
                <Typography variant="h4">Details</Typography>
              </Grid>
              <Grid
                item
                xs={3}
                container
                alignContent="center"
                justifyContent="flex-end"
              >
                <Tooltip
                  title={
                    <Typography fontSize={"1.1rem"}>
                      {`This section ${
                        areDetailsViewed ? "has" : "has not"
                      } been
                      viewed`}
                    </Typography>
                  }
                >
                  <Typography variant="h4">
                    {areDetailsViewed ? "✅" : "❌"}
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container rowSpacing={3}>
              <Grid item xs={12} container spacing={3}>
                <Grid item xs={3}>
                  <Typography variant="h6">
                    Overview of area & culture:
                  </Typography>
                </Grid>
                <Grid item xs={9} container alignItems="center">
                  <Typography>{atlas?.overviewOfCulture}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} container spacing={3}>
                <Grid item xs={3}>
                  <Typography variant="h6">Best time to go:</Typography>
                </Grid>
                <Grid item xs={9} container alignItems="center">
                  <Typography>{atlas?.bestTimeToGo}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} container spacing={3}>
                <Grid item xs={3}>
                  <Typography variant="h6">Best area to stay:</Typography>
                </Grid>
                <Grid item xs={9} container alignItems="center">
                  <Typography>{atlas?.bestAreaToStay}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} container spacing={3}>
                <Grid item xs={3}>
                  <Typography variant="h6">What to bring/pack:</Typography>
                </Grid>
                <Grid item xs={9} container alignItems="center">
                  <Typography>{atlas?.whatToBring}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} container spacing={3}>
                <Grid item xs={3}>
                  <Typography variant="h6">
                    Best mode of transportation:
                  </Typography>
                </Grid>
                <Grid item xs={9} container alignItems="center">
                  <Typography>{atlas?.bestRoute}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <CommentsAccordion>
          <AccordionSummary>
            <Grid container justifyContent="space-between">
              <Grid item xs={3}>
                <Typography variant="h4">Comments</Typography>
              </Grid>
              <Grid
                item
                xs={3}
                container
                alignContent="center"
                justifyContent="flex-end"
              ></Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            {commentsList.map((comment) => {
              return (
                <Grid>
                  <Grid>{comment.adminReview}</Grid>
                  <Grid>{`Sent by: ${
                    comment.reviewerName || comment.reviewerId
                  }`}</Grid>
                  <br />
                </Grid>
              );
            })}
          </AccordionDetails>
        </CommentsAccordion>
      </Grid>
      <Grid item container xs={12} my={3} justifyContent="center">
        <Grid item xs={6} sx={{ minHeight: 250 }}>
          {!atlas?.hasBeenApproved ? (
            <Paper sx={{ height: "100%", width: "100%", p: 4 }}>
              <Grid
                container
                alignContent="space-between"
                sx={{ height: "100%" }}
                rowSpacing={4}
              >
                <Grid item xs={12}>
                  <Typography variant={"h6"}>Approval Checklist</Typography>
                </Grid>
                <Grid item xs={12} container>
                  <Grid item xs={6} container spacing={2}>
                    <Grid item xs={9}>
                      <Typography>All Sections Viewed</Typography>
                    </Grid>
                    <Grid item>
                      <Typography>
                        {areDetailsViewed && isInfoViewed && authorInfoViewed
                          ? "✅"
                          : "❌"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} container spacing={2}>
                    <Grid item>
                      <Typography>All Experiences Approved</Typography>
                    </Grid>
                    <Grid item>
                      <Typography>
                        {Object.keys(experiences).every(
                          (id) => experiences[id]?.hasBeenApproved
                        )
                          ? "✅"
                          : "❌"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} container spacing={2}>
                    <Grid item xs={9}>
                      <Typography>
                        Correct Experience Count ({loadedExperiencesCount} /{" "}
                        {atlasExperienceCount})
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography>
                        {loadedExperiencesCount === atlasExperienceCount
                          ? "✅"
                          : "❌"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={4}>
                    <Typography>I affirm that I want to</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <ButtonGroup
                      variant="contained"
                      color={actionState === "Approve" ? "primary" : "inherit"}
                    >
                      <Button onClick={() => setActionState("Approve")}>
                        Approve
                      </Button>

                      <Button disabled>Remove</Button>

                      <Button
                        onClick={() => {
                          setToggleDialog(true);
                        }}
                      >
                        Request Changes
                      </Button>
                    </ButtonGroup>
                    {atlas !== undefined ? (
                      <RequestChanges
                        entity={atlas}
                        toggleModal={toggleDialog}
                        authorEmail={authorEmail}
                        setToggleModal={setToggleDialog}
                      />
                    ) : null}
                  </Grid>
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  {actionState === "Approve" ? (
                    <LoadingButton
                      loading={isApproving}
                      variant="contained"
                      sx={{ color: (theme) => theme.palette.grey[800] }}
                      disabled={
                        !(
                          Object.keys(experiences).every(
                            (id) => experiences[id]?.hasBeenApproved
                          ) &&
                          areDetailsViewed &&
                          isInfoViewed &&
                          authorInfoViewed &&
                          loadedExperiencesCount === atlasExperienceCount
                        )
                      }
                      onClick={() => {
                        setIsApproving(true);
                        const atlasLocation = atlas?.location as
                          | AtlasAddressCollection
                          | undefined;

                        if (
                          atlas &&
                          atlasLocation?.["United States"]?.["Hawaii"]
                        ) {
                          window.analytics.track("Hawaii Atlas Approved", {
                            uid: atlas.ownerId,
                            duration: atlas.duration.days,
                          });
                        }

                        if (authUser && atlas) {
                          PrivateAtlasQaApi.approveAtlas(authUser, atlas.id)
                            .then((atlas) => {
                              if (typeof atlas === "string") {
                                setApprovalError(atlas);
                              } else {
                                setApprovalError(undefined);
                                setAtlas(atlas);
                              }

                              setIsApproving(false);
                            })
                            .catch((e) => {
                              setApprovalError(e);
                              setIsApproving(false);
                            });
                        } else {
                          setIsApproving(false);
                          setApprovalError("Unknown client error");
                        }
                      }}
                    >
                      Approve
                    </LoadingButton>
                  ) : null}{" "}
                </Grid>
                <Grid item xs={12}>
                  {approvalError ? (
                    <Alert severity="error">{approvalError}</Alert>
                  ) : null}
                </Grid>
              </Grid>
            </Paper>
          ) : (
            <Alert severity="success">
              This atlas has already been approved!
            </Alert>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Atlas;
const getLocations = (addresses: AtlasAddressCollection) => {
  const locations: JSX.Element[] = [];

  Object.keys(addresses).forEach((country) => {
    Object.keys(addresses[country]).forEach((adminRegion) => {
      const cities: JSX.Element[] = [];
      let locationCount = 0;
      addresses[country][adminRegion].forEach((location) => {
        cities.push(
          <Link
            sx={{
              color: (theme) => theme.palette.secondary.main,
            }}
            key={location.country + location.adminRegion + location.city}
            href={`http://maps.google.com/?q=${location.city},${location.adminRegion}`}
            target={"blank"}
          >
            {locationCount !== 0 ? ", " : null}
            {location.city}
          </Link>
        );
        locationCount++;
      });

      const location = (
        <Typography component={"span"} key={country + adminRegion}>
          <b>{adminRegion}</b>: {cities} <br />
        </Typography>
      );
      locations.push(location);
    });
  });

  return locations;
};
