import { AtlasFirebaseSchema } from "@iwaandr/iwaandr-sdk";
import axios from "axios";
import { endpoint } from "env/env_interop";
import { User } from "firebase/auth";
import { getAuthHeader } from "utils/get_auther_header";

export class PrivateAtlasApi {
  static async getAtlas(
    user: User,
    atlasId: string
  ): Promise<AtlasFirebaseSchema> {
    const resp = await axios
      .get<{ atlas: AtlasFirebaseSchema }>(
        `${endpoint}/internal/admin/content/atlas`,
        {
          headers: {
            ...(await getAuthHeader(user)),
          },
          params: {
            atlasId,
          },
        }
      )
      .then((resp) => resp.data)
      .catch(() => {
        throw Error();
      });

    if (resp === undefined) {
      throw Error();
    }

    return resp.atlas;
  }
}
