import { Experience } from "@iwaandr/iwaandr-sdk";
import useAuth from "contexts/auth/hooks";
import { PrivateExperienceQaApi } from "features/qa/experience/private";

import * as React from "react";

export type ExperienceWithId = Experience & { id: string };

interface QaExperienceContextType {
  defaultExperiences: ExperienceWithId[] | undefined;
  getDefaultExperiences(): Promise<void>;
  isPolling: boolean;
  error: string | undefined;
}

export const QaExperienceContext = React.createContext<QaExperienceContextType>(
  null!
);

export function QaExperienceContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  let [defaultExperiences, setDefaultExperiences] = React.useState<
    ExperienceWithId[] | undefined
  >();
  let { authUser } = useAuth();
  let [isPolling, setIsPolling] = React.useState(false);
  let [error, setError] = React.useState<undefined | string>();

  let getDefaultAtlases = async (): Promise<void> => {
    if (!authUser) return;
    setIsPolling(true);
    PrivateExperienceQaApi.getExperiencesToQA(authUser)
      .then((data) => {
        setDefaultExperiences(data);
        setIsPolling(false);
      })
      .catch(() => {
        setError("There was an error fetching the atlases.");
        setIsPolling(false);
      });
  };

  return (
    <QaExperienceContext.Provider
      value={{
        getDefaultExperiences: getDefaultAtlases,
        defaultExperiences,
        isPolling,
        error,
      }}
    >
      {children}
    </QaExperienceContext.Provider>
  );
}
