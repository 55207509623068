import { IWaandrUser } from "@iwaandr/iwaandr-sdk";
import { Avatar, Box, Grid, Stack, Typography } from "@mui/material";
import * as React from "react";

interface UserViewProps {
  user: IWaandrUser | "not-found" | undefined;
  isValidUser: boolean;
}

const UserView: React.FunctionComponent<UserViewProps> = ({
  user,
  isValidUser,
}) => {
  if (user === "not-found") {
    return (
      <Box mt={3}>
        <Typography>No user found</Typography>
      </Box>
    );
  }

  if (user === undefined) {
    return (
      <Box mt={3}>
        <Typography>Search for a user</Typography>
      </Box>
    );
  }

  let content;

  if (isValidUser) {
    content = "User is eligible ✅";
  } else {
    content = "User is not eligible ❌";
  }

  return (
    <Box m={3} border="1px solid lightgrey" minHeight={100} width="80%">
      <Grid container alignContent="stretch" height="100%" my={4}>
        <Grid
          item
          xs={3}
          container
          justifyContent="center"
          alignItems="stretch"
          alignContent="center"
        >
          <Avatar
            src={user.photoUrl}
            sx={{
              height: 75,
              width: 75,
            }}
          />
        </Grid>
        <Grid
          item
          xs={9}
          container
          direction="column"
          justifyContent="center"
          wrap="nowrap"
        >
          <Grid item>
            <Typography variant="h5">{user.userName}</Typography>
          </Grid>
          <Grid item>
            <Typography>{user.bio}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Stack direction="row">
        <Typography mb={2} textAlign="center" width="100%" fontSize="1.75rem">
          {content}
        </Typography>
      </Stack>
    </Box>
  );
};

export default UserView;
