import { CompleteFeaturedCreatorProfile } from "@iwaandr/iwaandr-sdk";
import { Grid, Typography } from "@mui/material";
import * as React from "react";
import CreatorList from "./creator_list";
import CreatorView, { CreatorViewProps } from "./creator_view";

interface FeaturedCreatorsTableProps
  extends Pick<CreatorViewProps, "deleteCallback"> {
  /**
   * The content creators being shown.
   */
  allCreators: CompleteFeaturedCreatorProfile[] | undefined;
}

/**
 * A view of the featured users.
 */
const FeaturedCreatorsTable: React.FunctionComponent<FeaturedCreatorsTableProps> = ({
  allCreators,
  deleteCallback,
}) => {
  const [selectedCreator, setSelectedCreator] = React.useState<
    CompleteFeaturedCreatorProfile | undefined
  >();

  return (
    <Grid
      item
      container
      sx={{
        border: "1px solid black",
        height: 600,
        width: 900,
        borderRadius: 5,
      }}
    >
      <Grid
        item
        xs={3}
        borderRight="1px solid black"
        p={2}
        height={600}
        overflow="scroll"
      >
        <CreatorList
          selectedCreator={selectedCreator}
          setSelectedCreator={setSelectedCreator}
          allCreators={allCreators}
        />
      </Grid>
      <Grid
        item
        xs={9}
        p={3}
        container
        justifyContent="center"
        alignItems="center"
      >
        {selectedCreator === undefined ? (
          <Typography>Please select a creator</Typography>
        ) : (
          <CreatorView
            {...selectedCreator}
            deleteCallback={(userId: string) => {
              setSelectedCreator(undefined);
              deleteCallback(userId);
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default FeaturedCreatorsTable;
