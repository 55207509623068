import {
  AppStateExperience,
  Experience,
  ExperienceDraft,
} from "@iwaandr/iwaandr-sdk";
import axios from "axios";
import { endpoint } from "env/env_interop";
import { User } from "firebase/auth";
import { getAuthHeader } from "utils/get_auther_header";

export class PrivateExperienceApi {
  static async getExperience(
    user: User,
    experienceId: string,
    creatorId?: string,
    tryAsDraft = true
  ): Promise<AppStateExperience | ExperienceDraft> {
    const resp = await axios
      .get<{ experience: Experience | ExperienceDraft }>(
        `${endpoint}/internal/admin/content/experience`,
        {
          headers: {
            ...(await getAuthHeader(user)),
          },
          params: {
            experienceId,
            creatorId,
            tryAsDraft: tryAsDraft ? "1" : "0",
          },
        }
      )
      .then((resp) => resp.data)
      .catch(() => {
        throw Error();
      });

    if (resp === undefined) {
      throw Error();
    }

    return resp.experience;
  }
}
