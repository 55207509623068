import { Grid, Card, styled, Typography } from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";

const StyledCard = styled(Card)({
  position: "relative",
  transition: "all .2s ease-in-out",
  cursor: "pointer",
  "&:hover": {
    transform: "scale(1.1)",
  },
});

const backgroundImageStyles = {
  position: "absolute",
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  filter: "brightness(50%)",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  content: "''",
};

function Tools() {
  const navigate = useNavigate();

  return (
    <Grid container sx={{ height: "100%" }} justifyContent="center">
      <Grid container mt={10} height="fit-content">
        <Grid item xs={12} container>
          <Grid item xs={4} container justifyContent={"center"}>
            <StyledCard
              sx={{
                height: 200,
                width: 300,
                marginTop: "12.5px",
                marginLeft: 6,
                "::before": {
                  backgroundImage: "url(/images/textures/grey_stone.jpg)",
                  ...backgroundImageStyles,
                },
              }}
              onClick={() => {
                navigate("/app/tools/admin-post");
              }}
            >
              <Typography
                variant="h5"
                color="white"
                position="relative"
                mt={1}
                ml={1}
              >
                Admin Post
              </Typography>
            </StyledCard>
          </Grid>
          <Grid item xs={4}>
            <StyledCard
              sx={{
                height: 200,
                width: 300,
                marginTop: "12.5px",
                marginLeft: 6,
                "::before": {
                  backgroundImage: "url(/images/astro/star.jpg)",
                  ...backgroundImageStyles,
                },
              }}
              onClick={() => {
                navigate("/app/tools/update-search-atlases");
              }}
            >
              <Typography
                variant="h5"
                color="white"
                position="relative"
                mt={1}
                ml={1}
              >
                Migrate Atlases to Search
              </Typography>
            </StyledCard>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Tools;
