import * as React from "react";
import {
  Box,
  Grid,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import useAuth from "contexts/auth/hooks";
import { PrivateReportApi } from "features/report/api/private";
import Feed from "components/feed";
import ContentCard from "../../components/content-card";
import { createStyles, withStyles, WithStyles } from "@mui/styles";
import { FeedReportedContentEntityView } from "@iwaandr/iwaandr-sdk";

const styles = () =>
  createStyles({
    card: {
      borderRadius: 10,
      cursor: "pointer",
    },
    marginTop20: {
      marginTop: 20,
    },
    image: {
      borderRadius: 10,
    },
  });

type Filters = "unresolved" | "resolved";

interface ContentReportFeedProps extends WithStyles<typeof styles> {}

const ContentReportFeed: React.FC<ContentReportFeedProps> = ({
  classes,
}: ContentReportFeedProps) => {
  const BATCH_SIZE = 12;

  const { authUser } = useAuth();
  const [contentReports, setContentReports] = useState<
    FeedReportedContentEntityView[]
  >([]);
  const [lastReportId, setLastReportId] = useState<string>();
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [filter, setFilter] = useState<Filters>("unresolved");

  const handleFilterChange = (
    _event: React.MouseEvent<HTMLElement>,
    newFilter: Filters
  ) => {
    setFilter(newFilter);
  };

  const getContentReports = async () => {
    if (authUser && hasMore) {
      const reportPayload = await PrivateReportApi.getContentReports(
        authUser,
        BATCH_SIZE,
        lastReportId
      );

      const appendedReports = contentReports.concat(
        reportPayload.reports as FeedReportedContentEntityView[]
      );

      setContentReports(appendedReports);
      setLastReportId(reportPayload.lastReportId);
      setHasMore(
        !reportPayload || reportPayload.reports.length < BATCH_SIZE
          ? false
          : true
      );
    }
  };

  const generateCards = (showResolved?: boolean) => {
    const cards: JSX.Element[] = [];

    for (const report of contentReports) {
      if (report) {
        const card = (
          <Grid xs={6} key={report.reportId} item>
            <ContentCard report={report} />
          </Grid>
        );

        if (showResolved) {
          if (report.resolved) {
            cards.push(card);
          }
        } else {
          if (!report.resolved) {
            cards.push(card);
          }
        }
      }
    }

    const grid = (
      <Grid spacing={3} className={classes.marginTop20} container>
        {cards}
      </Grid>
    );

    return grid;
  };

  useEffect(() => {
    getContentReports();
  }, []);

  return (
    <div>
      <Box
        display={"flex"}
        className={classes.marginTop20}
        flexDirection={"row"}
        mt={2}
        mr={1}
        justifyContent={"space-between"}
      >
        <Stack>
          <Typography variant={"h4"}>
            {filter.substring(0, 1).toLocaleUpperCase() +
              filter.substring(1, filter.length)}{" "}
            Content Reports
          </Typography>
          <Typography>
            Scroll to view all the {filter} content reports!
          </Typography>
        </Stack>

        <ToggleButtonGroup
          value={filter}
          exclusive
          onChange={handleFilterChange}
        >
          <ToggleButton value="unresolved">Unresolved</ToggleButton>
          <ToggleButton value="resolved">Resolved</ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Feed
        items={filter === "resolved" ? generateCards(true) : generateCards()}
        dataLength={contentReports.length}
        fetchData={getContentReports}
        hasMore={hasMore}
      />
    </div>
  );
};

export default withStyles(styles)(ContentReportFeed);
