import { Grid, Typography, CircularProgress, Alert } from "@mui/material";
import useAtlasQa from "contexts/qa/atlas/hooks";
import * as React from "react";
import QaList from "components/qa/QaList/QaList";

function QaAtlases() {
  const { defaultAtlases, getDefaultAtlases, isPolling, error } = useAtlasQa();

  /**
   * Right now, poll every time the page is loaded.
   *
   * Because this is being stored in an app-wide context, we could
   * just display cached results, but while the query is fast and the
   * list is simple, pulling doesn't seem to hurt.
   */
  React.useEffect(() => {
    getDefaultAtlases();
  }, []);

  if (isPolling) {
    return (
      <Grid
        container
        sx={{ height: "100%" }}
        justifyContent="center"
        alignContent="center"
      >
        <CircularProgress size={50} />
      </Grid>
    );
  }

  return (
    <Grid container sx={{ height: "100%", mt: 2 }} justifyContent="center">
      {error ? (
        <Grid item xs={12}>
          <Alert severity="error">{error}</Alert>
        </Grid>
      ) : null}
      <Grid container item xs={12} justifyContent="center">
        <Grid item xs={12}>
          <Typography variant="h4" textAlign="center">
            Atlas QA
          </Typography>
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Typography>
            Below are current atlases that need to be reviewed. Click on one to
            navigate to the screen!
          </Typography>
        </Grid>
      </Grid>
      <QaList
        entitiesToShow={defaultAtlases}
        navigateHrefPrefix={`/app/atlas`}
      />
    </Grid>
  );
}

export default QaAtlases;
