export interface FirebaseConfig {
  apiKey?: string;
  authDomain?: string;
  databaseURL?: string;
  storageBucket?: string;
}

// String representation of possible environments when
// referencing the value of the environment variable itself.
export type envOptions = "dev" | "staging" | "prod" | "test" | undefined;

// All of the possible environments that can be used.
export enum Environment {
  staging = "staging",
  dev = "dev",
  prod = "prod",
  test = "test",
}

// A mapping of environment variables to object properties for convenience.
//
// NOTE: Not exhaustive of environment variables, just a sampling for ease of access.
export const envVariables = {
  // Whether or not the dev environment is being used.
  env: process.env.REACT_APP_ENV,

  // Whether or not to use the local endpoint. i.e. don't talk
  // to deployed servers
  useLocal: process.env.REACT_APP_USE_LOCAL,
};

// The active environment to use for the app.
//
// This defaults to `staging`.
export const activeEnvironment =
  Environment[(envVariables.env as envOptions) ?? Environment.staging];

/**
 * The current endpoint that can be used to talk to the database.
 */
export const endpoint = getFunctionsEndpointUrl();

// Get the URL that the "server functions" tab should be pointing to.
//
// This can be a local URL or a deployed one.
export function getFunctionsEndpointUrl(): string | undefined {
  const env = activeEnvironment;

  switch (env) {
    case "dev":
      if (envVariables.useLocal === "true") {
        return process.env.REACT_APP_LOCAL_DEV_URL;
      }

      return process.env.REACT_APP_SERVER_DEV_URL;
    case "staging":
      if (envVariables.useLocal === "true") {
        return process.env.REACT_APP_LOCAL_URL;
      }

      return process.env.REACT_APP_SERVER_URL;
    case "prod":
      if (envVariables.useLocal === "true") {
        return process.env.REACT_APP_LOCAL_PROD_URL;
      }

      return process.env.REACT_APP_SERVER_PROD_URL;
    case "test":
      if (envVariables.useLocal === "true") {
        return process.env.REACT_APP_LOCAL_TEST_URL;
      }

      return process.env.REACT_APP_SERVER_TEST_URL;
  }
}

// Build the Firebase config based on env variables.
export function buildConfig(): FirebaseConfig {
  const env = activeEnvironment ?? Environment.staging;

  return {
    apiKey: process.env[`REACT_APP_${env.toUpperCase()}_API_KEY`],
    authDomain: process.env[`REACT_APP_${env.toUpperCase()}_AUTH_DOMAIN`],
    databaseURL: process.env[`REACT_APP_${env.toUpperCase()}_DATABASE_URL`],
    storageBucket: process.env[`REACT_APP_${env.toUpperCase()}_STORAGE_BUCKET`],
  };
}
