import { CompleteFeaturedCreatorProfile } from "@iwaandr/iwaandr-sdk";
import {
  CircularProgress,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import * as React from "react";

interface CreatorListProps {
  /**
   * The creator that is currently active.
   */
  selectedCreator: CompleteFeaturedCreatorProfile | undefined;

  /**
   * The callback to set the selected creator.
   */
  setSelectedCreator: (creator: CompleteFeaturedCreatorProfile) => void;

  /**
   * The list of creators.
   */
  allCreators: CompleteFeaturedCreatorProfile[] | undefined;
}

/**
 * A list of featured content creators.
 */
const CreatorList: React.FunctionComponent<CreatorListProps> = ({
  selectedCreator,
  setSelectedCreator,
  allCreators,
}) => {
  if (allCreators === undefined) {
    return (
      <List sx={{ height: "100%" }}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <CircularProgress />
        </Grid>
      </List>
    );
  }

  if (allCreators.length === 0) {
    return (
      <List sx={{ height: "100%" }}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Typography>No featured creators</Typography>
        </Grid>
      </List>
    );
  }

  return (
    <List>
      {allCreators.map(({ uid, preferredName, ...rest }) => {
        return (
          <ListItemButton
            selected={selectedCreator?.uid === uid}
            key={uid}
            onClick={() => setSelectedCreator({ uid, preferredName, ...rest })}
          >
            <ListItemText primary={preferredName} />
          </ListItemButton>
        );
      })}
    </List>
  );
};

export default CreatorList;
