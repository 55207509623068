import { AtlasFirebaseSchema } from "@iwaandr/iwaandr-sdk";
import axios from "axios";
import { endpoint } from "env/env_interop";
import { User } from "firebase/auth";
import { getAuthHeader } from "utils/get_auther_header";

export class PrivateAtlasQaApi {
  static async getAtlasesToQA(user: User): Promise<AtlasFirebaseSchema[]> {
    const resp = await axios.get<AtlasFirebaseSchema[]>(
      `${endpoint}/internal/admin/qa/atlas`,
      {
        headers: {
          ...((await getAuthHeader(user)) ?? {}),
        },
      }
    );

    return resp.data;
  }

  static async approveAtlas(
    user: User,
    atlasId: string
  ): Promise<AtlasFirebaseSchema | string> {
    const value = await axios
      .post<{ experience: AtlasFirebaseSchema }>(
        `${endpoint}/internal/admin/content/atlas/approve`,
        { atlasId },
        {
          headers: {
            ...(await getAuthHeader(user)),
          },
        }
      )
      .then((resp) => resp.data?.experience ?? "Unknown response type.")
      .catch(() => "Failed to approve experience. ");

    return value;
  }
  static async requestChangesEmail(
    recipientEmail: string,
    authUser: User,
    message: string,
    entityTitle: string,
    entityType: string
  ) {
    const resp = axios.post(
      `${endpoint}/notification/request-changes`,
      {
        recipientEmail,
        message,
        entityTitle,
        entityType,
      },
      {
        headers: {
          ...(await getAuthHeader(authUser)),
        },
      }
    );
    return resp;
  }
  static async createAtlasQaComment(
    adminReview: string,
    authUser: User,
    atlasId: string,
    reviewerId: string
  ) {
    const resp = axios.post(
      `${endpoint}/internal/admin/qa/comment/atlas`,
      {
        adminReview,
        atlasId,
        reviewerId,
      },
      {
        headers: {
          ...(await getAuthHeader(authUser)),
        },
      }
    );
    return resp;
  }
}
