import { Box, Button, Typography } from "@mui/material";
import useAuth from "contexts/auth/hooks";
import { endpoint } from "env/env_interop";
import { PrivateToolUserApi } from "features/tools/users/api/private";
import * as React from "react";

interface AddAtlasesToIndexProps {}

const AddAtlasesToIndex: React.FunctionComponent<AddAtlasesToIndexProps> = () => {
  const { authUser } = useAuth();

  return (
    <Box mt={4}>
      <Typography variant="h4">Migrate Atlases to Algolia</Typography>
      <Typography variant="subtitle1">
        This button will look at the approved atlases and move them over to
        firestore to be indexed by Algolia.
      </Typography>

      <Button
        variant="contained"
        onClick={() => {
          if (!authUser) return;

          PrivateToolUserApi.indexAtlases(authUser);
        }}
      >
        Migrate
      </Button>
    </Box>
  );
};

export default AddAtlasesToIndex;
