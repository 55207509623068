import React from "react";
import theme from "theme";
import { Card, CardMedia, CardContent, Typography } from "@mui/material";
import { createStyles, withStyles, WithStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { FeedReportedUserEntityView } from "@iwaandr/iwaandr-sdk";

const styles = () =>
  createStyles({
    card: {
      borderRadius: 10,
      cursor: "pointer",
    },
    marginTop20: {
      marginTop: 20,
    },
    image: {
      borderRadius: 10,
    },
  });

interface UserCardProps extends WithStyles<typeof styles> {
  report: FeedReportedUserEntityView;
}

const UserCard: React.FC<UserCardProps> = ({
  report,
  classes,
}: UserCardProps) => {
  const navigate = useNavigate();
  const date = new Date(report.date).toDateString();

  return (
    <Card
      variant="outlined"
      className={classes.card}
      onClick={() => navigate(`/app/reports/user/${report.reportId}`)}
    >
      <CardMedia
        component={"img"}
        height={300}
        image={report.reporteeInfo.photoUrl}
      />
      <CardContent>
        <Typography>
          <b>Reason:</b> {report.reason}
        </Typography>
        <Typography>
          <b>Reported Date:</b> {date}
        </Typography>
        <Typography>
          <b>Status:</b>{" "}
          <Typography
            component={"span"}
            color={
              report.resolved
                ? theme.palette.success.main
                : theme.palette.error.main
            }
          >
            {report.resolved ? "Resolved" : "Unresolved"}
          </Typography>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(UserCard);
