import {
  AppStateExperience,
  IWaandrUser,
  ExperienceAdminReview,
} from "@iwaandr/iwaandr-sdk";
import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  CircularProgress,
  Grid,
  styled,
  Tooltip,
  Typography,
  Link,
  Paper,
  Button,
  Alert,
  ButtonGroup,
} from "@mui/material";
import useAuth from "contexts/auth/hooks";
import { PublicProfileApi } from "features/account/api/public";
import { PrivateExperienceApi } from "features/experiences/private";
import { PrivateExperienceQaApi } from "features/qa/experience/private";
import * as React from "react";
import { useParams } from "react-router-dom";
import RequestChanges from "components/qa/RequestChanges/requestChanges";
import { PrivateProfileApi } from "features/account/api/private";
import { PrivateInternalApi } from "features/internal/api/private";

interface ExperienceProps {}

const ExperienceImage = styled("img")({
  borderRadius: 10,
});
const CommentsAccordion = styled(Accordion)({
  marginTop: 20,
});

const Experience: React.FunctionComponent<ExperienceProps> = () => {
  const params = useParams();
  const { authUser } = useAuth();
  const [exp, setExp] = React.useState<AppStateExperience | undefined>();
  const [author, setAuthor] = React.useState<IWaandrUser | undefined>();
  const [isApproving, setIsApproving] = React.useState(false);
  const [approvalError, setApprovalError] = React.useState<
    string | undefined
  >();
  const [actionState, setActionState] = React.useState<"Approve" | undefined>();
  const [commentsList, setCommentsList] = React.useState<
    Array<{
      [key: string]: ExperienceAdminReview;
    }>
  >([]);
  const [toggleDialog, setToggleDialog] = React.useState(false);
  const [authorEmail, setAuthorEmail] = React.useState<string>();
  const [authorInfoViewed, setAuthorInfoViewed] = React.useState(false);
  const [isInfoViewed, setIsInfoViewed] = React.useState(false);
  const [areDetailsViewed, setAreDetailsViewed] = React.useState(false);

  React.useEffect(() => {
    if (params.experienceId === undefined || authUser === undefined) return;

    PrivateInternalApi.getExperienceQAComments(
      params.experienceId,
      authUser
    ).then((comments) => setCommentsList(comments));

    PrivateExperienceApi.getExperience(
      authUser,
      params.experienceId,
      undefined,
      false
    ).then((experience) => {
      const tExperience = experience as AppStateExperience;
      setExp(tExperience);
      PrivateProfileApi.getIWaandrUser(authUser, tExperience.userId).then(
        (experienceAuthor) => {
          experienceAuthor && experienceAuthor.email
            ? setAuthorEmail(experienceAuthor?.email)
            : console.log("experience author not defined");
        }
      );
      PublicProfileApi.getPublicProfileInfo(tExperience.userId).then(setAuthor);
    });
  }, [authUser, params.experienceId]);

  if (exp === undefined) {
    return (
      <Grid
        container
        sx={{ height: "100%" }}
        justifyContent="center"
        alignContent="center"
      >
        <CircularProgress />
      </Grid>
    );
  }

  const { location } = exp;
  const locationIsValid =
    location.adminRegion !== undefined &&
    location.city !== undefined &&
    location.country !== undefined;

  return (
    <Grid
      container
      sx={{ height: "100%", mt: 2 }}
      justifyContent="center"
      rowSpacing={3}
    >
      <Grid item xs={12} container justifyContent="center">
        <ExperienceImage
          src={exp.picture}
          alt={exp.title}
          height={320}
          width={450}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Typography variant="subtitle1" color="primary" textAlign="center">
            <i>You are reviewing...</i>
          </Typography>
        </Grid>
        <Grid container justifyContent="center">
          <Typography variant="h3">{exp.title}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={8}>
        <Accordion>
          <AccordionSummary
            onClick={() => {
              setAuthorInfoViewed(true);
            }}
          >
            <Grid container justifyContent="space-between">
              <Grid item xs={3}>
                <Typography variant="h4">Author Info</Typography>
              </Grid>
              <Grid
                item
                xs={3}
                container
                alignContent="center"
                justifyContent="flex-end"
              >
                <Tooltip
                  title={
                    <Typography fontSize={"1.1rem"}>
                      {`This section ${
                        authorInfoViewed ? "has" : "has not"
                      } been
                      viewed`}
                    </Typography>
                  }
                >
                  <Typography variant="h4">
                    {authorInfoViewed ? "✅" : "❌"}
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container rowSpacing={3} spacing={2}>
              <Grid item xs={12} container>
                <Grid item xs={3} container justifyContent="center">
                  <Avatar
                    src={author?.photoUrl}
                    sx={{ height: 75, width: 75 }}
                  />
                </Grid>
                <Grid item xs={6} container>
                  <Grid item xs={12}>
                    <Typography variant="h5">{author?.userName}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>{author?.bio}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={8}>
        <Accordion>
          <AccordionSummary
            onClick={() => {
              setIsInfoViewed(true);
            }}
          >
            <Grid container justifyContent="space-between">
              <Grid item xs={5}>
                <Typography variant="h4">Experience Info</Typography>
              </Grid>
              <Grid
                item
                xs={3}
                container
                alignContent="center"
                justifyContent="flex-end"
              >
                <Tooltip
                  title={
                    <Typography fontSize={"1.1rem"}>
                      {`This section ${isInfoViewed ? "has" : "has not"} been
                      viewed`}
                    </Typography>
                  }
                >
                  <Typography variant="h4">
                    {isInfoViewed ? "✅" : "❌"}
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container rowSpacing={3}>
              <Grid item xs={12} container>
                <Grid item xs={3}>
                  <Typography variant="h6">Locations:</Typography>
                </Grid>
                <Grid item xs={9} container alignItems="center">
                  <Link
                    href={`http://maps.google.com/?q=${exp.location.locationDescription}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Typography>{exp.location.locationDescription}</Typography>
                  </Link>
                </Grid>
              </Grid>
              <Grid item xs={12} container>
                <Grid item xs={3}>
                  <Typography variant="h6">Price:</Typography>
                </Grid>
                <Grid item xs={9} container alignItems="center">
                  <Typography>{exp.price}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} container>
                <Grid item xs={3}>
                  <Typography variant="h6">Type:</Typography>
                </Grid>
                <Grid item xs={9} container alignItems="center">
                  <Typography>{exp.isVisible ? "SINGLE" : "ATLAS"}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} container>
                <Grid item xs={3}>
                  <Typography variant="h6">Duration:</Typography>
                </Grid>
                <Grid item xs={9} container alignItems="center">
                  <Typography>{exp.length}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={8}>
        <Accordion>
          <AccordionSummary
            onClick={() => {
              setAreDetailsViewed(true);
            }}
          >
            <Grid container justifyContent="space-between">
              <Grid item xs={3}>
                <Typography variant="h4">Details</Typography>
              </Grid>
              <Grid
                item
                xs={3}
                container
                alignContent="center"
                justifyContent="flex-end"
              >
                <Tooltip
                  title={
                    <Typography fontSize={"1.1rem"}>
                      {`This section ${
                        areDetailsViewed ? "has" : "has not"
                      } been
                      viewed`}
                    </Typography>
                  }
                >
                  <Typography variant="h4">
                    {areDetailsViewed ? "✅" : "❌"}
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container rowSpacing={3}>
              <Grid item xs={12} container spacing={3}>
                <Grid item xs={3}>
                  <Typography variant="h6">What can you do:</Typography>
                </Grid>
                <Grid item xs={9} container alignItems="center">
                  <Typography>{exp.tipsDo}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} container spacing={3}>
                <Grid item xs={3}>
                  <Typography variant="h6">
                    Best route and mode of transportation:
                  </Typography>
                </Grid>
                <Grid item xs={9} container alignItems="center">
                  <Typography>{exp.tipsWay}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} container spacing={3}>
                <Grid item xs={3}>
                  <Typography variant="h6">Customs & Conservation:</Typography>
                </Grid>
                <Grid item xs={9} container alignItems="center">
                  <Typography>{exp.customsAndConservation}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} container spacing={3}>
                <Grid item xs={3}>
                  <Typography variant="h6">
                    Activity-specific details:
                  </Typography>
                </Grid>
                <Grid item xs={9} container alignItems="center">
                  <Typography>{exp.activitySpecificDetails}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} container spacing={3}>
                <Grid item xs={3}>
                  <Typography variant="h6">Go:</Typography>
                </Grid>
                <Grid container item xs={9}>
                  <Grid item xs={12} container>
                    <Grid item xs={5} container alignItems="center">
                      <Typography>(Dropdown)</Typography>
                    </Grid>
                    <Grid item xs={7} container alignItems="center">
                      <Typography>{exp.go.dropdown}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container>
                    <Grid item xs={5} container alignItems="center">
                      <Typography>(textbox)</Typography>
                    </Grid>
                    <Grid item xs={7} container alignItems="center">
                      <Typography>{exp.go.or}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} container spacing={3}>
                <Grid item xs={3}>
                  <Typography variant="h6">Bring:</Typography>
                </Grid>
                <Grid container item xs={9}>
                  <Grid item xs={12} container>
                    <Grid item xs={5} container alignItems="center">
                      <Typography>(Dropdown)</Typography>
                    </Grid>
                    <Grid item xs={7} container alignItems="center">
                      <Typography>{exp.bring.dropdown}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container>
                    <Grid item xs={5} container alignItems="center">
                      <Typography>(textbox)</Typography>
                    </Grid>
                    <Grid item xs={7} container alignItems="center">
                      <Typography>{exp.bring.or}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} container spacing={3}>
                <Grid item xs={3} sx={{ height: "100%" }}>
                  <Typography variant="h6">Park:</Typography>
                </Grid>
                <Grid container item xs={9}>
                  <Grid item xs={12} container>
                    <Grid item xs={5} container alignItems="center">
                      <Typography>(Dropdown)</Typography>
                    </Grid>
                    <Grid item xs={7} container alignItems="center">
                      <Typography>{exp.park.dropdown}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container>
                    <Grid item xs={5} container alignItems="center">
                      <Typography>(textbox)</Typography>
                    </Grid>
                    <Grid item xs={7} container alignItems="center">
                      <Typography>{exp.park.or}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <CommentsAccordion>
          <AccordionSummary>
            <Grid container justifyContent="space-between">
              <Grid item xs={3}>
                <Typography variant="h4">Comments</Typography>
              </Grid>
              <Grid
                item
                xs={3}
                container
                alignContent="center"
                justifyContent="flex-end"
              ></Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            {commentsList.map((comment) => {
              return (
                <Grid>
                  <Grid>{comment.adminReview}</Grid>
                  <Grid>
                    Sent by: {comment.reviewerName || comment.reviewerId}
                  </Grid>
                  <br />
                </Grid>
              );
            })}
          </AccordionDetails>
        </CommentsAccordion>
      </Grid>
      <Grid item container xs={12} my={3} justifyContent="center">
        {exp.hasBeenApproved ? (
          <Grid item xs={6}>
            <Alert>This experience has been approved already!</Alert>
          </Grid>
        ) : (
          <Grid item xs={6} sx={{ minHeight: 250 }}>
            <Paper sx={{ height: "100%", width: "100%", p: 4 }}>
              <Grid
                container
                alignContent="space-between"
                sx={{ height: "100%" }}
                rowSpacing={4}
              >
                <Grid item xs={12}>
                  <Typography variant={"h6"}>Approval Checklist</Typography>
                </Grid>
                <Grid item xs={12} container>
                  <Grid item xs={6} container spacing={2}>
                    <Grid item>
                      <Typography>All Sections Viewed</Typography>
                    </Grid>
                    <Grid item>
                      <Typography>
                        {areDetailsViewed && isInfoViewed && authorInfoViewed
                          ? "✅"
                          : "❌"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} container spacing={2}>
                    <Grid item>
                      <Typography>Location is valid</Typography>
                    </Grid>
                    <Grid item>
                      <Typography>{locationIsValid ? "✅" : "❌"}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={4}>
                    <Typography>I affirm that I want to</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <ButtonGroup
                      variant="contained"
                      color={actionState === "Approve" ? "primary" : "inherit"}
                    >
                      <Button onClick={() => setActionState("Approve")}>
                        Approve
                      </Button>

                      <Button disabled>Remove</Button>

                      <Button
                        onClick={() => {
                          setToggleDialog(true);
                        }}
                      >
                        Request Changes
                      </Button>
                    </ButtonGroup>
                    <RequestChanges
                      entity={exp}
                      toggleModal={toggleDialog}
                      authorEmail={authorEmail}
                      setToggleModal={setToggleDialog}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  {actionState === "Approve" ? (
                    <LoadingButton
                      variant="contained"
                      sx={{ color: (theme) => theme.palette.grey[800] }}
                      disabled={
                        !(
                          areDetailsViewed &&
                          isInfoViewed &&
                          authorInfoViewed &&
                          locationIsValid
                        )
                      }
                      loading={isApproving}
                      onClick={() => {
                        setIsApproving(true);
                        if (params.experienceId && authUser) {
                          PrivateExperienceQaApi.approveExperience(
                            authUser,
                            params.experienceId
                          ).then((resp) => {
                            console.log(resp);
                            if (typeof resp === "string") {
                              setApprovalError(resp);
                            } else {
                              setExp(resp);
                            }

                            setIsApproving(false);
                          });
                        } else {
                          setApprovalError(
                            "Failed to send request due to bad client state."
                          );
                          setIsApproving(false);
                        }
                      }}
                    >
                      Approve
                    </LoadingButton>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  {approvalError ? (
                    <Alert severity="error">{approvalError}</Alert>
                  ) : null}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Experience;
