import axios from "axios";
import { endpoint } from "env/env_interop";

export class PublicInternalApi {
  static async authAsAdmin(
    email: string,
    password: string
  ): Promise<string | undefined> {
    const resp = await axios.post<{ token: string } | string>(
      `${endpoint}/internal_public/admin/auth`,
      {
        email,
        password,
      }
    );

    if (typeof resp.data === "string") return;

    return resp.data.token;
  }
}
