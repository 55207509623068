import * as React from "react";
import { Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import useAuth from "contexts/auth/hooks";
import { useNavigate } from "react-router-dom";
import { createStyles, withStyles, WithStyles } from "@mui/styles";
import { PrivateProfileApi } from "features/account/api/private";
import Feed from "components/feed";
import { PublicIWaandrUser } from "@iwaandr/iwaandr-sdk";

const styles = () =>
  createStyles({
    card: {
      borderRadius: 10,
      cursor: "pointer",
    },
    marginTop20: {
      marginTop: 20,
    },
    image: {
      borderRadius: 10,
    },
  });

interface ReportProfileProps extends WithStyles<typeof styles> {}

const ReportProfileFeed: React.FC<ReportProfileProps> = ({
  classes,
}: ReportProfileProps) => {
  const BATCH_SIZE = 12;
  const navigate = useNavigate();

  const { authUser } = useAuth();
  const [profiles, setProfiles] = useState<PublicIWaandrUser[]>([]);
  const [lastUserId, setLastUserId] = useState<string>();
  const [hasMore, setHasMore] = useState<boolean>(true);

  useEffect(() => {
    getProfiles();
  }, []);

  const getProfiles = async () => {
    if (authUser && hasMore) {
      const profilePayload = await PrivateProfileApi.getUserFeed(
        authUser,
        BATCH_SIZE,
        lastUserId
      );

      if (!profilePayload || lastUserId === profilePayload?.lastUserId) {
        setHasMore(false);
        return;
      }

      const appendedProfiles = profiles.concat(
        profilePayload?.profiles as PublicIWaandrUser[]
      );

      setProfiles(appendedProfiles);
      setLastUserId(profilePayload?.lastUserId);
    }
  };

  const generateCards = () => {
    const cards: JSX.Element[] = [];

    for (const profile of profiles) {
      const name =
        !profile.userName || profile.userName?.trim() === ""
          ? "N/A"
          : profile.userName;
      const bio =
        !profile.bio || profile.bio?.trim() === "" ? "N/A" : profile.bio;

      if (profile) {
        const card = (
          <Grid xs={6} key={profile.userId} item>
            <Card
              variant="outlined"
              className={classes.card}
              onClick={() => navigate(`/app/reports/profile/${profile.userId}`)}
            >
              <CardMedia
                component={"img"}
                height={300}
                src={profile.photoUrl ?? "/logo.png"}
                alt={"user-image"}
              />
              <CardContent>
                <Typography>
                  <b>Id:</b> {profile.userId}
                </Typography>
                <Typography>
                  <b>Name:</b> {name}
                </Typography>
                <Typography>
                  <b>Bio:</b> {bio}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        );

        cards.push(card);
      }
    }

    const grid = (
      <Grid spacing={3} className={classes.marginTop20} container>
        {cards}
      </Grid>
    );

    return grid;
  };

  return (
    <div>
      <Feed
        items={generateCards()}
        dataLength={profiles.length}
        fetchData={getProfiles}
        hasMore={hasMore}
      />
    </div>
  );
};

export default withStyles(styles)(ReportProfileFeed);
