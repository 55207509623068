import React from "react";
import theme from "theme";
import { FeedReportedContentEntityView } from "@iwaandr/iwaandr-sdk";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Avatar,
  CardHeader,
} from "@mui/material";
import { createStyles, withStyles, WithStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";

const styles = () =>
  createStyles({
    card: {
      borderRadius: 10,
      cursor: "pointer",
    },
    marginTop20: {
      marginTop: 20,
    },
    image: {
      borderRadius: 10,
    },
  });

interface ContentCardProps extends WithStyles<typeof styles> {
  report: FeedReportedContentEntityView;
}

const ContentCard: React.FC<ContentCardProps> = ({
  report,
  classes,
}: ContentCardProps) => {
  const navigate = useNavigate();
  const date = new Date(report.date).toDateString();

  return (
    <Card
      variant="outlined"
      className={classes.card}
      onClick={() => navigate(`/app/reports/content/${report.reportId}`)}
    >
      <CardHeader
        avatar={
          <Avatar src={report.reporteeInfo.photoUrl} aria-label="user-photo" />
        }
        title={report.reporteeInfo.userName}
        subheader={
          !report.reporteeInfo.bio || report.reporteeInfo.bio?.trim() === ""
            ? "No bio provided."
            : report.reporteeInfo.bio
        }
        subheaderTypographyProps={{ color: theme.palette.gray }}
      />
      <CardMedia
        component={"img"}
        height={300}
        image={report.imageUrl}
        alt={"content-image"}
      />
      <CardContent>
        <Typography>
          <b>Type:</b> {report.entityType}
        </Typography>
        <Typography>
          <b>Reason:</b> {report.reason}
        </Typography>
        <Typography>
          <b>Reported Date:</b> {date}
        </Typography>
        <Typography>
          <b>Status:</b>{" "}
          <Typography
            component={"span"}
            color={
              report.resolved
                ? theme.palette.success.main
                : theme.palette.error.main
            }
          >
            {report.resolved ? "Resolved" : "Unresolved"}
          </Typography>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(ContentCard);
