import { responsiveFontSizes, createTheme } from "@mui/material";

/**
 * Theme developed at:
 *
 * https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=FDB338&secondary.color=1C77C7
 */
const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: "#FDB338",
        light: "#ffe56a",
        dark: "#c58400",
      },
      secondary: {
        light: "#d2e3e7",
        dark: "#004c96",
        main: "#4a90e2",
      },
      text: {
        primary: "#656565",
        secondary: "#fff",
      },
      background: {
        default: "#fff",
      },
      lightBlue: {
        main: "#4A90E2",
      },
      blue: {
        main: "#3b5998",
      },
      darkBlue: {
        main: "#1C77C7",
      },
      orange: {
        main: "#FDB338",
      },
      darkGray: {
        main: "#656565",
      },
      gray: {
        main: "#A4A4A4",
      },
      green: {
        main: "#53E079",
      },
      yellow: {
        main: "#F7D85A",
      },
      pink: {
        main: "#FF7176",
      },
    },
    typography: {
      fontFamily: '"Muli", "Roboto", "Helvetica", "Arial", sans-serif',
    },
  })
);

declare module "@mui/material/styles" {
  interface Palette {
    lightBlue: Pick<Palette["primary"], "main">;
    blue: Pick<Palette["primary"], "main">;
    darkBlue: Pick<Palette["primary"], "main">;
    orange: Pick<Palette["primary"], "main">;
    darkGray: Pick<Palette["primary"], "main">;
    gray: Pick<Palette["primary"], "main">;
    green: Pick<Palette["primary"], "main">;
    yellow: Pick<Palette["primary"], "main">;
    pink: Pick<Palette["primary"], "main">;
  }
  interface PaletteOptions {
    lightBlue: PaletteOptions["primary"];
    blue: PaletteOptions["primary"];
    darkBlue: PaletteOptions["primary"];
    orange: PaletteOptions["primary"];
    darkGray: PaletteOptions["primary"];
    gray: PaletteOptions["primary"];
    green: PaletteOptions["primary"];
    yellow: PaletteOptions["primary"];
    pink: PaletteOptions["primary"];
  }
}

export default theme;
