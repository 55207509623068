import { Box, Typography } from "@mui/material";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

interface FeedProps {
  /**
   * Feed items
   */
  items: JSX.Element;

  /**
   * Flag that signifies if there is more data
   */
  hasMore: boolean;

  /**
   * The number of records items has.
   */
  dataLength: number;

  /**
   * Height of feed
   */
  height?: number;

  /**
   * Callback to fetch more data
   */
  fetchData: () => void;
}

const Feed: React.FC<FeedProps> = ({
  items,
  hasMore,
  dataLength,
  height,
  fetchData,
}: FeedProps) => {
  return (
    <InfiniteScroll
      height={height}
      dataLength={dataLength} //This is important field to render the next data
      next={fetchData}
      hasMore={hasMore}
      loader={
        <Box mt={3}>
          <Typography variant={"h5"} textAlign={"center"}>
            Loading...
          </Typography>
        </Box>
      }
      endMessage={
        <Box mt={3}>
          <Typography variant={"h5"} textAlign={"center"}>
            Those are all the reports!
          </Typography>
        </Box>
      }
    >
      {items}
    </InfiniteScroll>
  );
};

export default Feed;
