import axios from "axios";
import { SimpleUserRepresentation } from "contexts/tools/users/context";
import { endpoint } from "env/env_interop";
import { User } from "firebase/auth";
import { getAuthHeader } from "utils/get_auther_header";

export class PrivateToolUserApi {
  static async getUsers(user: User): Promise<SimpleUserRepresentation[]> {
    const resp = await axios
      .get<{ users: SimpleUserRepresentation[] }>(
        `${endpoint}/internal/admin/tool/users`,
        {
          headers: {
            ...(await getAuthHeader(user)),
          },
        }
      )
      .then((resp) => resp.data)
      .catch(() => {
        throw Error();
      });

    if (resp === undefined) {
      throw Error();
    }

    return resp.users;
  }

  static async indexAtlases(user: User): Promise<void> {
    await axios.post(
      `${endpoint}/internal/admin/tool/index-atlases`,
      {},
      {
        headers: {
          ...(await getAuthHeader(user)),
        },
      }
    );
  }
}
