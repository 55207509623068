import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";

interface DeleteStatusDialogProps {
  /**
   * A callback to delete the featured user's featured status.
   */
  deleteCallback: () => void;

  /**
   * Whether the dialog is open.
   */
  open: boolean;

  /**
   * Callback to close the dialog.
   */
  closeDialog: () => void;
}

const DeleteStatusDialog: React.FunctionComponent<DeleteStatusDialogProps> = ({
  open,
  deleteCallback,
  closeDialog,
}) => {
  return (
    <Dialog open={open}>
      <Grid container direction="column">
        <Grid item container justifyContent="space-between">
          <Grid item xs={9}>
            <DialogTitle>Remove Featured Status</DialogTitle>
          </Grid>

          <Grid
            item
            xs={2}
            container
            justifyContent="center"
            alignContent="center"
          >
            <IconButton onClick={closeDialog}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContent>
          <Grid container direction="column">
            <Grid item>
              <Typography
                fontWeight="bold"
                textAlign="center"
                fontSize="1.25rem"
              >
                Are you sure?
              </Typography>
            </Grid>
            <Grid item>
              <Typography textAlign="center">
                This will remove the featured creator status for this user.
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="inherit" onClick={closeDialog}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              closeDialog();
              deleteCallback();
            }}
          >
            Proceed
          </Button>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};

export default DeleteStatusDialog;
