import {
  FeedReportedContentEntityView,
  FeedReportedUserEntityView,
  IWaandrUser,
  ReportProfile,
} from "@iwaandr/iwaandr-sdk";
import {
  Avatar,
  Divider,
  Grid,
  Paper,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { createStyles, withStyles, WithStyles } from "@mui/styles";
import Feed from "components/feed";
import ContentCard from "../../components/content-card";
import UserCard from "../../components/user-card";
import useAuth from "contexts/auth/hooks";
import { PrivateProfileApi } from "features/account/api/private";
import { PrivateReportApi } from "features/report/api/private";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const styles = () =>
  createStyles({
    gridContainer: {
      marginTop: 20,
    },
    padding15: {
      padding: 15,
    },
    marginTop10: {
      marginTop: 10,
    },
    image: {
      borderRadius: 10,
    },
  });

type FeedType = "contentMade" | "contentReceived" | "userMade" | "userReceived";
type Filter = "unresolved" | "resolved";

interface Feed {
  userMade?: {
    reports: FeedReportedUserEntityView[];
    hasMore: boolean;
    lastId?: string;
  };
  userReceived?: {
    reports: FeedReportedUserEntityView[];
    hasMore: boolean;
    lastId?: string;
  };
  contentMade?: {
    reports: FeedReportedContentEntityView[];
    hasMore: boolean;
    lastId?: string;
  };
  contentReceived?: {
    reports: FeedReportedContentEntityView[];
    hasMore: boolean;
    lastId?: string;
  };
}

interface ReportProfileViewProps extends WithStyles<typeof styles> {}

const ReportProfileView: React.FC<ReportProfileViewProps> = ({ classes }) => {
  const BATCH_SIZE = 12;
  const params = useParams();
  const { authUser } = useAuth();
  const userId = params.userId;

  const [report, setReport] = useState<ReportProfile | null>(null);
  const [profile, setProfile] = useState<IWaandrUser | null>(null);
  const [feed, setFeed] = useState<Feed>({
    userMade: {
      reports: [],
      hasMore: true,
      lastId: undefined,
    },
    userReceived: {
      reports: [],
      hasMore: true,
      lastId: undefined,
    },
    contentMade: {
      reports: [],
      hasMore: true,
      lastId: undefined,
    },
    contentReceived: {
      reports: [],
      hasMore: true,
      lastId: undefined,
    },
  });
  const [feedType, setFeedType] = useState<FeedType>("userMade");
  const [filter, setFilter] = useState<Filter>("unresolved");

  const fetchData = () => {
    if (feedType === "userMade") {
      userMade();
      return;
    }

    if (feedType === "userReceived") {
      userReceived();
      return;
    }

    if (feedType === "contentMade") {
      contentMade();
      return;
    }

    if (feedType === "contentReceived") {
      contentReceived();
      return;
    }
  };

  useEffect(() => {
    if (!authUser || !userId) return;

    PrivateReportApi.getReportProfile(authUser, userId).then((report) => {
      setReport(report);
    });

    PrivateProfileApi.getIWaandrUser(authUser, userId).then((profile) => {
      setProfile(profile);
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, [feedType]);

  const handleFeedChange = (
    _event: React.MouseEvent<HTMLElement>,
    newFeed: FeedType
  ) => {
    setFeedType(newFeed);
  };

  const handleFilterChange = (
    _event: React.MouseEvent<HTMLElement>,
    newFilter: Filter
  ) => {
    setFilter(newFilter);
  };

  const checkIfNullOrUndefined = (item: string | undefined) => {
    if (!item) return "N/A";
    if (item.trim() === "") return "N/A";

    return item;
  };

  const userMade = async () => {
    const ref = feed.userMade;

    if (!authUser || !userId || ref?.hasMore === false) return;

    const payload = await PrivateReportApi.getUserMadeUserReports(
      authUser,
      userId,
      BATCH_SIZE,
      ref?.lastId
    );

    const appendedReports =
      ref?.reports.concat(
        (payload?.reports as FeedReportedUserEntityView[]) ?? []
      ) ?? [];

    setFeed({
      ...feed,
      userMade: {
        reports: appendedReports,
        lastId: payload?.lastReportId,
        hasMore: !payload || payload.reports.length < BATCH_SIZE ? false : true,
      },
    });
  };

  const userReceived = async () => {
    const ref = feed.userReceived;

    if (!authUser || !userId || ref?.hasMore === false) return;

    const payload = await PrivateReportApi.getUserReceivedUserReports(
      authUser,
      userId,
      BATCH_SIZE,
      ref?.lastId
    );

    const appendedReports =
      ref?.reports.concat(
        (payload?.reports as FeedReportedUserEntityView[]) ?? []
      ) ?? [];

    setFeed({
      ...feed,
      userReceived: {
        reports: appendedReports,
        lastId: payload?.lastReportId,
        hasMore: !payload || payload.reports.length < BATCH_SIZE ? false : true,
      },
    });
  };

  const contentMade = async () => {
    const ref = feed.contentMade;

    if (!authUser || !userId || ref?.hasMore === false) return;

    const payload = await PrivateReportApi.getUserMadeContentReports(
      authUser,
      userId,
      BATCH_SIZE,
      ref?.lastId
    );

    const appendedReports =
      ref?.reports.concat(
        (payload?.reports as FeedReportedContentEntityView[]) ?? []
      ) ?? [];

    setFeed({
      ...feed,
      contentMade: {
        reports: appendedReports,
        lastId: payload?.lastReportId,
        hasMore: !payload || payload.reports.length < BATCH_SIZE ? false : true,
      },
    });
  };

  const contentReceived = async () => {
    if (!authUser || !userId) return;

    const payload = await PrivateReportApi.getUserReceivedContentReports(
      authUser,
      userId,
      BATCH_SIZE,
      feed?.contentReceived?.lastId
    );

    const appendedReports =
      feed.contentReceived?.reports.concat(
        (payload?.reports as FeedReportedContentEntityView[]) ?? []
      ) ?? [];

    setFeed({
      ...feed,
      contentReceived: {
        reports: appendedReports,
        lastId: payload?.lastReportId,
        hasMore: !payload || payload.reports.length < BATCH_SIZE ? false : true,
      },
    });
  };

  const pushCard = (
    report: FeedReportedContentEntityView | FeedReportedUserEntityView,
    push: () => void
  ) => {
    if (filter === "unresolved") {
      if (!report.resolved) {
        push();
      }
    } else {
      if (report.resolved) {
        push();
      }
    }
  };

  const generateCards = () => {
    const cards: JSX.Element[] = [];
    const reports = feed[feedType]?.reports;

    if (reports) {
      for (const report of reports) {
        if (feedType === "contentMade" || feedType === "contentReceived") {
          const card = (
            <Grid xs={6} key={report.reportId} item>
              <ContentCard report={report as FeedReportedContentEntityView} />
            </Grid>
          );

          pushCard(report, () => cards.push(card));
        } else {
          const card = (
            <Grid xs={6} key={report.reportId} item>
              <UserCard report={report as FeedReportedUserEntityView} />
            </Grid>
          );

          pushCard(report, () => cards.push(card));
        }
      }
    }

    const grid = (
      <Grid spacing={3} container>
        {cards}
      </Grid>
    );

    return grid;
  };

  if (!report) return null;

  return (
    <Grid
      container
      display={"flex"}
      className={classes.gridContainer}
      spacing={2}
    >
      <Grid item xs={4} justifyContent="center">
        <Paper className={classes.padding15}>
          <Stack spacing={1}>
            <Stack
              direction={"row"}
              spacing={2}
              justifyContent={"space-between"}
            >
              <Stack spacing={1}>
                <Typography variant={"h6"}>User Details</Typography>
                <Typography>
                  <b>User Id:</b> {checkIfNullOrUndefined(profile?.userId)}
                </Typography>
                <Typography>
                  <b>Name:</b> {checkIfNullOrUndefined(profile?.userName)}
                </Typography>
                <Typography>
                  <b>Bio:</b> {checkIfNullOrUndefined(profile?.bio)}
                </Typography>
              </Stack>
              <Avatar
                src={profile?.photoUrl}
                className={classes.marginTop10}
                sx={{ height: "70px", width: "70px" }}
              />
            </Stack>

            <Divider />

            <Typography variant={"h6"}>Report Summary</Typography>
            <Typography>
              <b>User Reports Made:</b> {report.madeUserReports}
            </Typography>
            <Typography>
              <b>User Reports Received:</b> {report.receivedUserReports}
            </Typography>
            <Typography>
              <b>Content Reports Made:</b> {report.madeContentReports}
            </Typography>
            <Typography>
              <b>Content Reports Received:</b> {report.receivedContentReports}
            </Typography>
          </Stack>
        </Paper>
        <Typography variant={"h6"} className={classes.marginTop10}>
          Connected Reports
        </Typography>
        <Typography>
          Click a button to see the corresponding reports for this user.
        </Typography>
        <ToggleButtonGroup
          className={classes.marginTop10}
          orientation={"vertical"}
          value={feedType}
          exclusive
          fullWidth
          onChange={handleFeedChange}
        >
          <ToggleButton value="userMade">User Reports Made</ToggleButton>
          <ToggleButton value="userReceived">
            User Reports Received
          </ToggleButton>
          <ToggleButton value="contentMade">Content Reports Made</ToggleButton>
          <ToggleButton value="contentReceived">
            Content Reports Received
          </ToggleButton>
        </ToggleButtonGroup>

        <ToggleButtonGroup
          className={classes.marginTop10}
          value={filter}
          exclusive
          onChange={handleFilterChange}
          fullWidth
        >
          <ToggleButton value="unresolved">Unresolved</ToggleButton>
          <ToggleButton value="resolved">Resolved</ToggleButton>
        </ToggleButtonGroup>
      </Grid>

      <Grid item xs={8} justifyContent="center">
        <Feed
          height={750}
          items={generateCards()}
          dataLength={feed[feedType]?.reports.length ?? 0}
          fetchData={() => fetchData()}
          hasMore={feed[feedType]?.hasMore ?? false}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ReportProfileView);
