import {
  Alert,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { PrivateProfileApi } from "features/account/api/private";
import useAuth from "contexts/auth/hooks";
import { IWaandrUser } from "@iwaandr/iwaandr-sdk";
import UserView from "./user_view";
import { LoadingButton } from "@mui/lab";
import { PrivateFeaturedCreatorsApi } from "../../../api/private";

interface FormDialogProps {
  /**
   * Whether or not the form dialog is open.
   */
  open: boolean;

  /**
   * The callback to close the dialog.
   */
  closeDialog: () => void;

  /**
   * A callback that both closes the dialog and triggers a data refresh.
   */
  closeAndRefetch: () => void;
}

/**
 * The dialog used to create a new featured user.
 */
const FormDialog: React.FunctionComponent<FormDialogProps> = ({
  open,
  closeDialog,
  closeAndRefetch,
}) => {
  const fetchUserTimeout = React.useRef<any>();
  const [userId, setUserId] = React.useState("");
  const [user, setUser] = React.useState<
    IWaandrUser | "not-found" | undefined
  >();
  const [isFetchingUser, setIsFetchingUser] = React.useState(false);
  const { authUser } = useAuth();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [quote, setQuote] = React.useState("");
  const [preferredName, setPreferredName] = React.useState("");
  const [error, setError] = React.useState<undefined | string>();

  const isValidUser =
    (user &&
      typeof user !== "string" &&
      user?.userName !== undefined &&
      user?.photoUrl !== undefined &&
      user?.bio !== undefined) ??
    false;

  const quoteCount = quote.length;
  const QUOTE_MAX = 140;
  const remainingCharacters = QUOTE_MAX - quoteCount;

  const isFormValid =
    isValidUser && quote.length > 0 && preferredName.length > 0;

  return (
    <>
      <Dialog open={open} fullWidth>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={(e: React.FormEvent) => {
            e.preventDefault();

            if (authUser === undefined) {
              return;
            }

            setIsSubmitting(true);
            PrivateFeaturedCreatorsApi.createFeaturedCreator({
              adminUser: authUser,
              uid: userId,
              quote,
              preferredName,
            })
              .then((wasSuccessful) => {
                setIsSubmitting(false);

                if (!wasSuccessful) {
                  setError("The user could not be added.");
                } else {
                  setQuote("");
                  setUser(undefined);
                  setUserId("");
                  setPreferredName("");
                  closeAndRefetch();
                }
              })
              .catch(() => {
                setIsSubmitting(false);
                setError("An unexpected error was encountered.");
              });
          }}
        >
          <Grid container direction="column">
            <Grid item container justifyContent="space-between">
              <Grid item xs={9}>
                <DialogTitle>Create a new featured creator</DialogTitle>
              </Grid>

              <Grid
                item
                xs={2}
                container
                justifyContent="center"
                alignContent="center"
              >
                <IconButton onClick={closeDialog}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <DialogContent>
            <Typography mb={2}>Enter the user's ID</Typography>
            <TextField
              fullWidth
              id="outlined-textarea"
              value={userId}
              onChange={async (e) => {
                clearTimeout(fetchUserTimeout.current);
                setUserId(e.target.value);

                if (!authUser) {
                  console.warn("no auth user detected");
                  return;
                }

                setIsFetchingUser(true);

                fetchUserTimeout.current = setTimeout(() => {
                  if (
                    e.target.value === undefined ||
                    e.target.value.length === 0
                  ) {
                    setUser(undefined);
                    setIsFetchingUser(false);
                    return;
                  }

                  PrivateProfileApi.getIWaandrUser(authUser, e.target.value)
                    .then((userData) => {
                      if (userData === null) {
                        setUser("not-found");
                      } else {
                        setUser(userData);
                      }

                      setIsFetchingUser(false);
                    })
                    .catch(() => setIsFetchingUser(false));
                }, 500);
              }}
            />

            <Box minHeight={150} borderTop="1px solid lightgrey" mt={3}>
              <Grid container justifyContent="center" alignItems="center">
                {isFetchingUser ? (
                  <CircularProgress sx={{ my: 4 }} />
                ) : (
                  <UserView user={user} isValidUser={isValidUser} />
                )}
              </Grid>
            </Box>
            <Box minHeight={150} borderTop="1px solid lightgrey" mt={3} pt={3}>
              <Typography mb={2}>Provide the user's preferred name</Typography>
              <TextField
                rows={3}
                fullWidth
                value={preferredName}
                onChange={(e) => {
                  setPreferredName(e.target.value);
                }}
              />

              <Typography my={2}>Provide the user's quote</Typography>
              <TextField
                error={remainingCharacters < 0}
                multiline
                rows={3}
                fullWidth
                value={quote}
                onChange={(e) => {
                  setQuote(e.target.value);
                }}
                FormHelperTextProps={{
                  sx: {
                    color: "black",
                  },
                }}
                helperText={
                  <Typography variant="subtitle1">
                    {remainingCharacters} characters left
                  </Typography>
                }
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={!isFormValid}
              loading={isSubmitting}
              sx={{
                color: "white",
              }}
            >
              Create
            </LoadingButton>
          </DialogActions>
        </Box>
      </Dialog>
      <Snackbar
        open={error !== undefined}
        autoHideDuration={2000}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        onClose={() => setError(undefined)}
      >
        <Alert severity="error" sx={{ width: 400 }}>
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default FormDialog;
