import {
  CreateFeaturedContentCreator,
  DeleteFeaturedContentCreator,
} from "@iwaandr/iwaandr-sdk";
import axios from "axios";
import { endpoint } from "env/env_interop";
import { User } from "firebase/auth";
import { getAuthHeader } from "utils/get_auther_header";

export interface CreateFeauturedCreatorBody
  extends CreateFeaturedContentCreator {
  adminUser: User;
}

export interface DeleteFeauturedCreatorBody
  extends DeleteFeaturedContentCreator {
  adminUser: User;
}

export class PrivateFeaturedCreatorsApi {
  /**
   * Create a featured content creator.
   */
  static async createFeaturedCreator({
    adminUser,
    ...restBody
  }: CreateFeauturedCreatorBody): Promise<boolean> {
    const result = await axios
      .post<"success" | string>(
        `${endpoint}/internal/admin/featured/creator/create`,
        restBody,
        {
          headers: {
            ...(await getAuthHeader(adminUser)),
          },
        }
      )
      .then((resp) => resp.data)
      .catch(() => {
        return null;
      });

    if (typeof result === "string" && result === "success") {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Deletes the featured status for a particular user.
   */
  static async deleteFeaturedCreator({
    adminUser,
    ...restBody
  }: DeleteFeauturedCreatorBody): Promise<boolean> {
    const result = await axios
      .post<"success" | string>(
        `${endpoint}/internal/admin/featured/creator/delete`,
        restBody,
        {
          headers: {
            ...(await getAuthHeader(adminUser)),
          },
        }
      )
      .then((resp) => resp.data)
      .catch(() => {
        return null;
      });

    if (typeof result === "string" && result === "success") {
      return true;
    } else {
      return false;
    }
  }
}
