import { AtlasFirebaseSchema } from "@iwaandr/iwaandr-sdk";
import useAuth from "contexts/auth/hooks";
import { PrivateAtlasQaApi } from "features/qa/atlas/private";

import * as React from "react";

interface QaAtlasContextType {
  defaultAtlases: AtlasFirebaseSchema[] | undefined;
  getDefaultAtlases(): Promise<void>;
  isPolling: boolean;
  error: string | undefined;
}

export const QaAtlasContext = React.createContext<QaAtlasContextType>(null!);

export function QaAtlasContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  let [defaultAtlases, setDefaultAtlases] = React.useState<
    AtlasFirebaseSchema[] | undefined
  >();
  let { authUser } = useAuth();
  let [isPolling, setIsPolling] = React.useState(false);
  let [error, setError] = React.useState<undefined | string>();

  let getDefaultAtlases = async (): Promise<void> => {
    if (!authUser) return;
    setIsPolling(true);
    PrivateAtlasQaApi.getAtlasesToQA(authUser)
      .then((data) => {
        setDefaultAtlases(data);
        setIsPolling(false);
      })
      .catch(() => {
        setError("There was an error fetching the atlases.");
        setIsPolling(false);
      });
  };

  return (
    <QaAtlasContext.Provider
      value={{
        getDefaultAtlases,
        defaultAtlases,
        isPolling,
        error,
      }}
    >
      {children}
    </QaAtlasContext.Provider>
  );
}
