import {
  Grid,
  Paper,
  Stack,
  Card,
  CardMedia,
  styled,
  Typography,
  Tooltip,
} from "@mui/material";
import useUserTool from "contexts/tools/users/hooks";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import { CircularProgress } from "@mui/material";

const StyledCard = styled(Card)({
  position: "relative",
  transition: "all .2s ease-in-out",
  cursor: "pointer",
  "&:hover": {
    transform: "scale(1.1)",
  },
});

const backgroundImageStyles = {
  position: "absolute",
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  filter: "brightness(50%)",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  content: "''",
};

const Indicator = styled(Paper)({
  padding: 10,
});

function Home() {
  const navigate = useNavigate();
  const { getUsers, error, users } = useUserTool();

  React.useEffect(() => {
    if (users === undefined) {
      getUsers();
    }
  }, []);

  let UserCountContent;

  if (users === undefined) {
    if (error !== undefined) {
      UserCountContent = <Typography>---</Typography>;
    } else {
      UserCountContent = <CircularProgress size={20} />;
    }
  } else {
    UserCountContent = <Typography>{users.length}</Typography>;
  }

  return (
    <Grid container sx={{ height: "100%" }} justifyContent="center">
      <Grid
        item
        xs={12}
        container
        mt={5}
        sx={{
          backgroundColor: "secondary.light",
          py: 1,
          borderRadius: 3,
        }}
      >
        <Grid item xs={2} />
        <Grid item xs={1}>
          <Tooltip
            title={
              <Typography>how many users the iWaandr platform has</Typography>
            }
          >
            <Indicator>
              <Grid container>
                <Grid item xs={4} container justifyContent="center">
                  <PersonIcon />
                </Grid>
                <Grid item xs={8} container justifyContent="center">
                  {UserCountContent}
                </Grid>
              </Grid>
            </Indicator>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" mt={5} height="fit-content">
        <StyledCard
          sx={{
            height: 450,
            width: 600,
            "::before": {
              backgroundImage: "url(/images/atlases.jpg)",
              ...backgroundImageStyles,
            },
          }}
          onClick={() => {
            navigate("/app/qa/atlases");
          }}
        >
          <Typography
            variant="h3"
            color="white"
            position="relative"
            mt={3}
            ml={3}
          >
            QA Atlases
          </Typography>
        </StyledCard>
        <Stack justifyContent="center">
          <StyledCard
            sx={{
              height: 200,
              width: 300,
              marginLeft: 6,
              "::before": {
                backgroundImage: "url(/images/experiences.jpg)",
                ...backgroundImageStyles,
              },
            }}
            onClick={() => {
              navigate("/app/qa/experiences");
            }}
          >
            <Typography
              variant="h5"
              color="white"
              position="relative"
              mt={1}
              ml={1}
            >
              QA Experiences
            </Typography>
          </StyledCard>
          <StyledCard
            sx={{
              height: 200,
              width: 300,
              marginTop: "12.5px",
              marginLeft: 6,
              "::before": {
                backgroundImage: "url(/images/reported.jpg)",
                ...backgroundImageStyles,
              },
            }}
            onClick={() => {
              navigate("/app/reports");
            }}
          >
            <Typography
              variant="h5"
              color="white"
              position="relative"
              mt={1}
              ml={1}
            >
              Review Reports
            </Typography>
          </StyledCard>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default Home;
