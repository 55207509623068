import axios from "axios";
import { endpoint } from "env/env_interop";
import { getAuthHeader } from "utils/get_auther_header";
import { User } from "firebase/auth";
import { AtlasAdminReview, ExperienceAdminReview } from "@iwaandr/iwaandr-sdk";
export class PrivateInternalApi {
  static async getAtlasQaComments(
    atlasId: string,
    user: User
  ): Promise<Array<{ key: AtlasAdminReview }>> {
    const value = axios
      .get(`${endpoint}/internal/admin/qa/comments/atlas`, {
        headers: {
          ...(await getAuthHeader(user)),
        },
        params: {
          atlasId,
        },
      })
      .then((res) => res.data);
    return value;
  }
  static async getExperienceQAComments(
    experienceId: string,
    user: User
  ): Promise<Array<{ key: ExperienceAdminReview }>> {
    const value = axios
      .get(`${endpoint}/internal/admin/qa/comments/experience`, {
        headers: {
          ...(await getAuthHeader(user)),
        },
        params: {
          experienceId,
        },
      })
      .then((res) => res.data);
    return value;
  }
}
