import {
  CompleteFeaturedCreatorProfile,
  isAccountComplete,
} from "@iwaandr/iwaandr-sdk";
import {
  Avatar,
  Button,
  Divider,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import * as React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import DeleteStatusDialog from "./delete_status_dialog";

export interface CreatorViewProps extends CompleteFeaturedCreatorProfile {
  /**
   * A callback that can be used to delete a particular user.
   */
  deleteCallback: (userId: string) => void;
}

/**
 * The view for a particular featured creator that shows
 * important profile information.
 */
const CreatorView: React.FunctionComponent<CreatorViewProps> = ({
  preferredName,
  quote,
  bio,
  photoUrl,
  deleteCallback,
  uid,
}) => {
  const [removeDialogIsOpen, setRemoveDialogIsOpen] = React.useState(false);
  const isValid = isAccountComplete(
    { userName: preferredName, bio, photoUrl },
    {
      skipProps: ["bio"],
    }
  );

  let badge: React.ReactElement;

  if (isValid) {
    badge = (
      <Tooltip title={<Typography>This user is in compliance</Typography>}>
        <CheckCircleIcon color="success" fontSize="large" sx={{ ml: 1 }} />
      </Tooltip>
    );
  } else {
    badge = (
      <Tooltip title={<Typography>This user is out of compliance</Typography>}>
        <CloseIcon color="error" sx={{ ml: 1 }} />
      </Tooltip>
    );
  }

  return (
    <>
      <Grid container direction="column" height="100%" position="relative">
        <Grid item container justifyContent="center">
          <Avatar src={photoUrl} sx={{ height: 100, width: 100 }} />
        </Grid>
        <Grid item>
          <Typography
            variant="h4"
            component={Grid}
            item
            container
            alignItems="center"
            justifyContent="center"
          >
            {preferredName} {badge}
          </Typography>
        </Grid>
        <Grid item my={2}>
          <Typography textAlign="center" variant="h5">
            {bio}
          </Typography>
        </Grid>
        <Divider />
        <Grid item container justifyContent="center" my={3}>
          <Grid
            item
            xs={10}
            component={Paper}
            p={2}
            color="black"
            bgcolor="secondary.light"
            elevation={0}
          >
            <Typography fontSize="1.25rem" textAlign="center">
              "<i>{quote}</i> "
            </Typography>
          </Grid>
        </Grid>
        <Grid
          position={"absolute"}
          bottom={0}
          right={0}
          container
          spacing={2}
          justifyContent="end"
        >
          <Grid item>
            <Button
              color="error"
              variant="contained"
              sx={{ color: "white" }}
              onClick={() => setRemoveDialogIsOpen(true)}
            >
              Remove Featured Status
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <DeleteStatusDialog
        open={removeDialogIsOpen}
        closeDialog={() => setRemoveDialogIsOpen(false)}
        deleteCallback={() => deleteCallback(uid)}
      />
    </>
  );
};

export default CreatorView;
