import {
  AppBar,
  Avatar,
  Button,
  Container,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  styled,
} from "@mui/material";
import { Logout, Map, Image, Receipt } from "@mui/icons-material";
import useAuth from "contexts/auth/hooks";
import * as React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";

const Logo = styled("img")({
  height: 50,
  width: 50,
  cursor: "pointer",
});

function BasicLayout() {
  const navigate = useNavigate();
  const { appUser, logOut, isAuthed } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!isAuthed) {
    return (
      <Container sx={{ height: "100%" }} disableGutters maxWidth={false}>
        <Outlet />
      </Container>
    );
  }

  return (
    <>
      <AppBar elevation={1} color="inherit" position="sticky">
        <Grid container my={1}>
          <Grid item xs={1} />
          <Grid item xs={1}>
            <Logo
              src="/logo.png"
              onClick={() => {
                navigate("/app/home");
              }}
            />
          </Grid>
          <Grid container item xs={7} alignContent="center">
            <Button
              variant="text"
              href="/app/qa/atlases"
              color="inherit"
              sx={{ height: "100%", padding: "0px 20px" }}
              startIcon={<Map />}
              size="large"
              onClick={(e) => {
                e.preventDefault();
                navigate("/app/qa/atlases");
              }}
            >
              QA Atlases
            </Button>
            <Button
              variant="text"
              href="/app/qa/experiences"
              color="inherit"
              sx={{ height: "100%", padding: "0px 20px" }}
              startIcon={<Image />}
              size="large"
              onClick={(e) => {
                e.preventDefault();
                navigate("/app/qa/experiences");
              }}
            >
              QA Experiences
            </Button>
            <Button
              variant="text"
              href="/app/reports"
              color="inherit"
              sx={{ height: "100%", padding: "0px 20px" }}
              startIcon={<Receipt />}
              size="large"
              onClick={(e) => {
                e.preventDefault();
                navigate("/app/reports");
              }}
            >
              View Reports
            </Button>
            <Button
              variant="text"
              href="/app/reports"
              color="inherit"
              sx={{ height: "100%", padding: "0px 20px" }}
              startIcon={<BuildCircleIcon />}
              size="large"
              onClick={(e) => {
                e.preventDefault();
                navigate("/app/tools");
              }}
            >
              Tools
            </Button>
          </Grid>
          <Grid
            container
            item
            xs={2}
            justifyContent="flex-end"
            alignContent="center"
          >
            <IconButton
              onClick={handleClick}
              size="small"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar
                alt={appUser?.userName}
                src={appUser?.photoUrl}
                sx={{
                  transition: "all .2s ease-in-out",
                  cursor: "pointer",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
              />
            </IconButton>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </AppBar>
      <Container>
        <Outlet />
      </Container>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={logOut}>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}

export default BasicLayout;
