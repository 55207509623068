import { IWaandrUser, ProfilePayload } from "@iwaandr/iwaandr-sdk";
import axios from "axios";
import { endpoint } from "env/env_interop";
import { User } from "firebase/auth";
import { getAuthHeader } from "utils/get_auther_header";

export class PrivateProfileApi {
  /** Fetches the profile data for the user attached the user ID */
  static async getProfileData(user: User): Promise<IWaandrUser | null> {
    const resp = await axios
      .get<IWaandrUser>(`${endpoint}/account/get-user-data`, {
        headers: {
          ...(await getAuthHeader(user)),
        },
      })
      .then((resp) => resp.data)
      .catch(() => {
        console.warn("Failed to retrieve user");
        return null;
      });

    return resp;
  }

  /**
   * Gets iWaandr user
   * @param user
   * @param userId
   */
  static async getIWaandrUser(
    user: User,
    userId: string
  ): Promise<IWaandrUser | null> {
    const resp = await axios
      .get<IWaandrUser | string>(`${endpoint}/internal/admin/account/user`, {
        headers: {
          ...(await getAuthHeader(user)),
        },
        params: {
          userId,
        },
      })
      .then((resp) => resp.data)
      .catch(() => {
        console.warn("Failed to retrieve user");
        return null;
      });

    if (resp === undefined || typeof resp === "string") {
      return null;
    }

    return resp;
  }

  /**
   * Gets iWaandr users for feed
   * @param user
   * @param batchSize
   * @param lastUserId
   */
  static async getUserFeed(
    user: User,
    batchSize: number,
    lastUserId?: string
  ): Promise<ProfilePayload | null> {
    const resp = await axios
      .get<ProfilePayload>(`${endpoint}/internal/admin/account/user/feed`, {
        headers: {
          ...(await getAuthHeader(user)),
        },
        params: {
          batchSize,
          lastUserId,
        },
      })
      .then((resp) => resp.data)
      .catch(() => {
        console.warn("Failed to retrieve user feed");
        return null;
      });

    return resp;
  }
}
