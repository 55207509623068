import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import * as React from "react";
import { subWeeks } from "date-fns";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { AtlasFirebaseSchema } from "@iwaandr/iwaandr-sdk";
import { ExperienceWithId } from "contexts/qa/experience/context";

interface VersionedEntity {
  version: number;
  title: string;
  id: string;
}

interface QaListProps {
  /**
   * List items are linked to specific entity pages, and this prop controls what
   * the majority of that URL is. The entity ID will be attached to the end.
   *
   * For example, if this prop is set to `/a/path/passed/in`, a list item could
   * have the url of:
   * `/a/path/padded/in/some-id-here`.
   */
  navigateHrefPrefix: string;

  /**
   * A list of entities that need to be QA'd, to be displayed as list items
   * with basic information that can be clicked on to navigate to a page for
   * that entity.
   */
  entitiesToShow: VersionedEntity[] | undefined;
}

function isAnAtlas(entity: VersionedEntity): entity is AtlasFirebaseSchema {
  return "postingDate" in entity;
}

function isAnExperience(entity: VersionedEntity): entity is ExperienceWithId {
  return "timestamp" in entity;
}

const QaList: React.FunctionComponent<QaListProps> = ({
  navigateHrefPrefix,
  entitiesToShow,
}) => {
  const navigate = useNavigate();

  return (
    <Grid item xs={8} mt={4}>
      <Grid container>
        <Grid item xs={2} container justifyContent="center">
          <Typography>Date Updated</Typography>
        </Grid>
        <Grid item xs={2} container justifyContent="center">
          <Typography>Version</Typography>
        </Grid>
        <Grid item xs={7} container justifyContent="center">
          <Typography>Title</Typography>
        </Grid>
        <Grid item xs={1} container justifyContent="center" />
      </Grid>
      <List>
        {entitiesToShow?.map((entity) => {
          let datePosted: number | undefined | null;

          if (isAnAtlas(entity)) {
            datePosted = entity.postingDate;
          } else if (isAnExperience(entity)) {
            datePosted = entity.timestamp;
          }

          return (
            <ListItem
              key={entity.title}
              components={{
                Root: Paper,
              }}
              sx={{
                cursor: "pointer",
                p: 1,
                transition: "all .2s ease-in-out",
                "&:hover": {
                  backgroundColor: (theme) => theme.palette.secondary.light,
                  transform: "scale(1.075)",
                },
              }}
              onClick={() => {
                navigate(`${navigateHrefPrefix}/${entity.id}`);
              }}
            >
              <Grid container alignContent="center">
                <Grid container item xs={2} alignContent="center">
                  {datePosted ? (
                    <ListItemText
                      sx={{
                        textAlign: "center",
                        padding: 1,
                        color: "white",
                        borderRadius: 1,
                        backgroundColor: (theme) => {
                          const atlasDate = new Date(datePosted!);
                          const rightNow = new Date();
                          const aWeekAgo = subWeeks(rightNow, 1);
                          const twoWeeksAgo = subWeeks(rightNow, 2);

                          if (atlasDate.getTime() > aWeekAgo.getTime()) {
                            return theme.palette.success.light;
                          } else if (
                            atlasDate.getTime() > twoWeeksAgo.getTime()
                          ) {
                            return theme.palette.warning.light;
                          } else {
                            return theme.palette.error.light;
                          }
                        },
                      }}
                    >
                      {new Date(datePosted).toISOString().substring(0, 10)}
                    </ListItemText>
                  ) : null}
                </Grid>
                <Grid
                  item
                  xs={2}
                  container
                  alignContent="center"
                  justifyContent="center"
                >
                  <Typography textAlign="center">{entity.version}</Typography>
                </Grid>
                <Grid item xs={7} container alignContent="center">
                  <ListItemText>{entity.title}</ListItemText>
                </Grid>
                <Grid item xs={1} container alignContent="center">
                  <IconButton>
                    <ArrowForwardIosIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </ListItem>
          );
        })}
      </List>
    </Grid>
  );
};

export default QaList;
