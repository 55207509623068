import {
  CompleteFeaturedCreatorProfile,
  GetFeaturedCreatorsParams,
} from "@iwaandr/iwaandr-sdk";
import axios from "axios";
import { endpoint } from "env/env_interop";

export class PublicFeaturedCreatorsApi {
  /**
   * Gets a list of creators
   */
  static async getFeaturedContentCreators(): Promise<
    CompleteFeaturedCreatorProfile[]
  > {
    const params: GetFeaturedCreatorsParams = {
      enforceRequiredProperties: false,
    };
    const creators = await axios
      .get<{ creators: CompleteFeaturedCreatorProfile[] }>(
        `${endpoint}/featured_public/creators`,
        {
          params,
        }
      )
      .then((resp) => resp.data.creators)
      .catch(() => {
        return null;
      });

    if (creators === null) {
      return [];
    }

    return creators;
  }
}
