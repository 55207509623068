import {
  TextField,
  Stack,
  Alert,
  Grid,
  styled,
  Paper,
  Typography,
} from "@mui/material";
import useAuth from "contexts/auth/hooks";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

const BackgroundImage = styled("img")({
  position: "absolute",
  height: "100vh",
  width: "100vw",
  objectFit: "cover",
});

function Login() {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState<undefined | string>();
  const [isLoading, setIsLoading] = React.useState(false);
  const { logIn, isAuthed } = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (isAuthed) {
      return navigate("/app/home");
    }
  }, [isAuthed]);

  return (
    <Grid
      container
      sx={{ height: "100%" }}
      alignContent="center"
      justifyContent="center"
    >
      <BackgroundImage src="/images/login.png" sx={{ zIndex: -1 }} />
      <Paper sx={{ height: 400, width: "33%" }}>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            setIsLoading(true);
            const resp = await logIn(email, password);

            if (resp === "failed") {
              setError("Unauthorized");
              setIsLoading(false);
            }
          }}
        >
          <Stack justifyContent="space-between">
            <Typography variant="h3" textAlign="center" mt={4}>
              iWaandr Admin
            </Typography>
            <TextField
              variant="filled"
              placeholder="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ my: 2, mx: 3 }}
            />
            <TextField
              variant="filled"
              placeholder="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              sx={{ my: 2, mx: 3 }}
            />
            <Grid container justifyContent="center">
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isLoading}
              >
                Log In
              </LoadingButton>
            </Grid>
          </Stack>
        </form>
        {error !== undefined ? (
          <Alert severity="error" sx={{ my: 2 }}>
            You are not authorized.
          </Alert>
        ) : null}
      </Paper>
    </Grid>
  );
}

export default Login;
