import { Fab, Grid, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import * as React from "react";
import { PublicFeaturedCreatorsApi } from "features/featured/creators/api/public";
import FeaturedCreatorsTable from "features/featured/creators/components/table";
import CreateDialog from "features/featured/creators/components/dialogs/form_dialog";
import useAuth from "contexts/auth/hooks";
import { PrivateFeaturedCreatorsApi } from "features/featured/creators/api/private";
import { CompleteFeaturedCreatorProfile } from "@iwaandr/iwaandr-sdk";

interface FeaturedContentCreatorsProps {}

/**
 * The tool for managing featured content creators.
 */
const FeaturedContentCreators: React.FunctionComponent<FeaturedContentCreatorsProps> = () => {
  const [dialogIsOpen, setDialogIsOpen] = React.useState(false);
  const [allCreators, setAllCreators] = React.useState<
    CompleteFeaturedCreatorProfile[] | undefined
  >();
  const [refetchTrigger, setRefetchTrigger] = React.useState(0);
  const { authUser } = useAuth();

  React.useEffect(() => {
    PublicFeaturedCreatorsApi.getFeaturedContentCreators().then(
      (listOfCreators) => {
        setAllCreators(listOfCreators);
      }
    );
  }, [refetchTrigger]);

  return (
    <>
      <Grid item container justifyContent="center" my={6}>
        <Typography variant="h3" component="h1">
          iWaandr Featured Content Creators
        </Typography>
      </Grid>

      <Grid container item justifyContent="center">
        <FeaturedCreatorsTable
          allCreators={allCreators}
          deleteCallback={async (uid: string) => {
            if (!authUser) return;

            PrivateFeaturedCreatorsApi.deleteFeaturedCreator({
              adminUser: authUser,
              uid,
            }).then(() => setRefetchTrigger((curr) => curr + 1));
          }}
        />
      </Grid>

      <Fab
        color="secondary"
        sx={{ position: "absolute", bottom: 50, right: 50 }}
        onClick={() => setDialogIsOpen(true)}
      >
        <AddIcon />
      </Fab>
      <CreateDialog
        open={dialogIsOpen}
        closeDialog={() => setDialogIsOpen(false)}
        closeAndRefetch={() => {
          setDialogIsOpen(false);
          setRefetchTrigger((curr) => curr + 1);
        }}
      />
    </>
  );
};

export default FeaturedContentCreators;
