import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "pages/app/home";
import Login from "pages/login";
import BasicLayout from "components/layouts/BasicLayout/BasicLayout";
import QaExperiences from "pages/app/qa/experiences";
import QaAtlases from "pages/app/qa/atlases";
import ViewReports from "pages/app/reports";
import { CircularProgress, Container, ThemeProvider } from "@mui/material";
import { AuthProvider } from "contexts/auth/context";

import { initializeApp } from "firebase/app";
import { buildConfig } from "env/env_interop";
import useAuth from "contexts/auth/hooks";
import theme from "theme";

import "index.css";
import { QaAtlasContextProvider } from "contexts/qa/atlas/context";
import Atlas from "pages/app/atlas";
import Experience from "pages/app/experience";
import { QaExperienceContextProvider } from "contexts/qa/experience/context";
import ContentReportFeed from "pages/app/reports/content/feed";
import ContentReportView from "pages/app/reports/content/view";
import UserReportFeed from "pages/app/reports/user/feed";
import UserReportView from "pages/app/reports/user/view";
import ProfileReportFeed from "pages/app/reports/profile/feed";
import ProfileReportView from "pages/app/reports/profile/view";
import Tools from "pages/app/tools";
import AdminPost from "pages/app/tools/admin_post";
import { UserToolContextProvider } from "contexts/tools/users/context";
import FeaturedContentCreators from "pages/app/featured_content_creators";
import AddAtlasesToIndex from "pages/app/tools/add_atlases_to_index";

initializeApp(buildConfig());

function RequireAuth({ children }: { children: JSX.Element }) {
  let { isAuthed } = useAuth();

  if (!isAuthed) {
    return <Navigate to="/login" replace />;
  }

  return children;
}

const App = () => {
  const { isLoading } = useAuth();

  if (isLoading) {
    return (
      <Container>
        <CircularProgress
          color="primary"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      </Container>
    );
  }
  return (
    <Routes>
      <Route path="/" element={<BasicLayout />}>
        <Route index element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/app">
          <Route
            path="/app/home"
            element={
              <RequireAuth>
                <Home />
              </RequireAuth>
            }
          />
          <Route path="/app/featured">
            <Route
              path="/app/featured/creators"
              element={
                <RequireAuth>
                  <FeaturedContentCreators />
                </RequireAuth>
              }
            />
          </Route>
          <Route
            path="/app/reports"
            element={
              <RequireAuth>
                <ViewReports />
              </RequireAuth>
            }
          />
          <Route
            path="/app/reports/content"
            element={
              <RequireAuth>
                <ContentReportFeed />
              </RequireAuth>
            }
          />
          <Route
            path="/app/reports/content/:reportId"
            element={
              <RequireAuth>
                <ContentReportView />
              </RequireAuth>
            }
          />
          <Route
            path="/app/reports/user"
            element={
              <RequireAuth>
                <UserReportFeed />
              </RequireAuth>
            }
          />
          <Route
            path="/app/reports/user/:reportId"
            element={
              <RequireAuth>
                <UserReportView />
              </RequireAuth>
            }
          />
          <Route
            path="/app/reports/profile"
            element={
              <RequireAuth>
                <ProfileReportFeed />
              </RequireAuth>
            }
          />
          <Route
            path="/app/reports/profile/:userId"
            element={
              <RequireAuth>
                <ProfileReportView />
              </RequireAuth>
            }
          />
          <Route
            path="/app/tools"
            element={
              <RequireAuth>
                <Tools />
              </RequireAuth>
            }
          />
          <Route
            path="/app/atlas/:atlasId"
            element={
              <RequireAuth>
                <Atlas />
              </RequireAuth>
            }
          />
          <Route
            path="/app/experience/:experienceId"
            element={
              <RequireAuth>
                <Experience />
              </RequireAuth>
            }
          />
          <Route path="/app/tools">
            <Route
              path="/app/tools/admin-post"
              element={
                <RequireAuth>
                  <AdminPost />
                </RequireAuth>
              }
            />
            <Route
              path="/app/tools/update-search-atlases"
              element={
                <RequireAuth>
                  <AddAtlasesToIndex />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="/app/qa">
            <Route
              path="/app/qa/atlases"
              element={
                <RequireAuth>
                  <QaAtlases />
                </RequireAuth>
              }
            />
            <Route
              path="/app/qa/experiences"
              element={
                <RequireAuth>
                  <QaExperiences />
                </RequireAuth>
              }
            />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AuthProvider>
          <QaAtlasContextProvider>
            <QaExperienceContextProvider>
              <UserToolContextProvider>
                <App />
              </UserToolContextProvider>
            </QaExperienceContextProvider>
          </QaAtlasContextProvider>
        </AuthProvider>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
