import axios from "axios";
import {
  ReportedContentEntityView,
  ReportedUserEntityView,
  ReportPayload,
  ReportProfile,
} from "@iwaandr/iwaandr-sdk";
import { User } from "firebase/auth";
import { endpoint } from "env/env_interop";
import { getAuthHeader } from "utils/get_auther_header";

export class PrivateReportApi {
  /**
   * Gets single content report
   * @param user
   * @param reportId
   */
  static async getContentReport(
    user: User,
    reportId: string
  ): Promise<ReportedContentEntityView> {
    const resp = await axios
      .get<ReportedContentEntityView>(
        `${endpoint}/internal/admin/report/content`,
        {
          headers: {
            ...(await getAuthHeader(user)),
          },
          params: {
            reportId,
          },
        }
      )
      .then((resp) => resp.data)
      .catch(() => {
        throw Error();
      });

    if (resp === undefined) {
      throw Error();
    }

    return resp;
  }

  /**
   * Gets content reports
   * @param user
   * @param batchSize
   * @param lastContentReportId
   */
  static async getContentReports(
    user: User,
    batchSize: number,
    lastContentReportId?: string
  ): Promise<ReportPayload> {
    const resp = await axios
      .get<ReportPayload>(`${endpoint}/internal/admin/report/content/feed`, {
        headers: {
          ...(await getAuthHeader(user)),
        },
        params: {
          batchSize,
          lastContentReportId,
        },
      })
      .then((resp) => resp.data)
      .catch(() => {
        throw Error();
      });

    if (resp === undefined) {
      throw Error();
    }

    return resp;
  }

  /**
   * Gets user made content reports
   * @param user
   * @param userId
   * @param batchSize
   * @param lastContentReportId
   */
  static async getUserMadeContentReports(
    user: User,
    userId: string,
    batchSize: number,
    lastContentReportId?: string
  ): Promise<ReportPayload | undefined> {
    const resp = await axios
      .get<ReportPayload>(
        `${endpoint}/internal/admin/report/user/content/made`,
        {
          headers: {
            ...(await getAuthHeader(user)),
          },
          params: {
            userId,
            batchSize,
            lastContentReportId,
          },
        }
      )
      .then((resp) => resp.data)
      .catch(() => {
        return undefined;
      });

    return resp;
  }

  /**
   * Gets user received content reports
   * @param user
   * @param userId
   * @param batchSize
   * @param lastContentReportId
   */
  static async getUserReceivedContentReports(
    user: User,
    userId: string,
    batchSize: number,
    lastContentReportId?: string
  ): Promise<ReportPayload | undefined> {
    const resp = await axios
      .get<ReportPayload>(
        `${endpoint}/internal/admin/report/user/content/received`,
        {
          headers: {
            ...(await getAuthHeader(user)),
          },
          params: {
            userId,
            batchSize,
            lastContentReportId,
          },
        }
      )
      .then((resp) => resp.data)
      .catch(() => {
        return undefined;
      });

    return resp;
  }

  /**
   * Gets single user report
   * @param user
   * @param reportId
   */
  static async getUserReport(
    user: User,
    reportId: string
  ): Promise<ReportedUserEntityView> {
    const resp = await axios
      .get<ReportedUserEntityView>(`${endpoint}/internal/admin/report/user`, {
        headers: {
          ...(await getAuthHeader(user)),
        },
        params: {
          reportId,
        },
      })
      .then((resp) => resp.data)
      .catch(() => {
        throw Error();
      });

    if (resp === undefined) {
      throw Error();
    }

    return resp;
  }

  /**
   * Gets user reports
   * @param user
   * @param batchSize
   * @param lastUserReportId
   */
  static async getUserReports(
    user: User,
    batchSize: number,
    lastUserReportId?: string
  ): Promise<ReportPayload> {
    const resp = await axios
      .get<ReportPayload>(`${endpoint}/internal/admin/report/user/feed`, {
        headers: {
          ...(await getAuthHeader(user)),
        },
        params: {
          batchSize,
          lastUserReportId,
        },
      })
      .then((resp) => resp.data)
      .catch(() => {
        throw Error();
      });

    if (resp === undefined) {
      throw Error();
    }

    return resp;
  }

  /**
   * Gets user made user reports
   * @param user
   * @param userId
   * @param batchSize
   * @param lastUserReportId
   */
  static async getUserMadeUserReports(
    user: User,
    userId: string,
    batchSize: number,
    lastUserReportId?: string
  ): Promise<ReportPayload | undefined> {
    const resp = await axios
      .get<ReportPayload>(`${endpoint}/internal/admin/report/user/made`, {
        headers: {
          ...(await getAuthHeader(user)),
        },
        params: {
          userId,
          batchSize,
          lastUserReportId,
        },
      })
      .then((resp) => resp.data)
      .catch(() => {
        return undefined;
      });

    return resp;
  }

  /**
   * Gets user received user reports
   * @param user
   * @param userId
   * @param batchSize
   * @param lastUserReportId
   */
  static async getUserReceivedUserReports(
    user: User,
    userId: string,
    batchSize: number,
    lastUserReportId?: string
  ): Promise<ReportPayload | undefined> {
    const resp = await axios
      .get<ReportPayload>(`${endpoint}/internal/admin/report/user/received`, {
        headers: {
          ...(await getAuthHeader(user)),
        },
        params: {
          userId,
          batchSize,
          lastUserReportId,
        },
      })
      .then((resp) => resp.data)
      .catch(() => {
        return undefined;
      });

    return resp;
  }

  /**
   * Get user report profile if it exists
   * @param user
   * @param userId
   */
  static async getReportProfile(
    user: User,
    userId: string
  ): Promise<ReportProfile | null> {
    const resp = await axios
      .get<ReportProfile>(`${endpoint}/internal/admin/report/profile`, {
        headers: {
          ...(await getAuthHeader(user)),
        },
        params: {
          userId,
        },
      })
      .then((resp) => resp.data)
      .catch(() => {
        throw Error();
      });

    if (resp === undefined) {
      throw Error();
    }

    return resp;
  }
}
