import { IWaandrUser } from "@iwaandr/iwaandr-sdk";
import { PrivateProfileApi } from "features/account/api/private";
import { PublicInternalApi } from "features/internal/api/public";
import {
  getAuth,
  setPersistence,
  browserLocalPersistence,
  signInWithCustomToken,
  signOut,
  User,
  onAuthStateChanged,
} from "firebase/auth";
import * as React from "react";

interface AuthContextType {
  authUser?: User;
  appUser?: IWaandrUser;
  logIn: (user: string, callback: string) => Promise<string>;
  logOut: () => Promise<void>;
  isAuthed: boolean;
  isLoading: boolean;
}

export const AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  let [authUser, setAuthUser] = React.useState<User | undefined>();
  let [appUser, setAppUser] = React.useState<IWaandrUser | undefined>();
  let [isLoadingInitialAuth, setIsLoadingInitialAuth] = React.useState(true);

  React.useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user && authUser === undefined) {
        const iwaandrUser = await PrivateProfileApi.getProfileData(user);
        if (iwaandrUser) {
          setAppUser(iwaandrUser);
          setAuthUser(user);
        }
      } else {
        if (authUser !== undefined) {
          setAppUser(undefined);
          setAuthUser(undefined);
        }
      }
      setIsLoadingInitialAuth(false);
    });
  }, []);

  let logIn = async (
    email: string,
    password: string
  ): Promise<"failed" | "success"> => {
    try {
      const result = await PublicInternalApi.authAsAdmin(email, password);

      if (result === undefined) throw Error();

      const auth = getAuth();
      await setPersistence(auth, browserLocalPersistence).then(async () => {
        const authUser = await signInWithCustomToken(auth, result);
        const iwaandrUser = await PrivateProfileApi.getProfileData(
          authUser.user
        );
        if (iwaandrUser) {
          setAppUser(iwaandrUser);
          setAuthUser(authUser.user);
        }
      });
    } catch (e) {
      return "failed";
    }

    return "success";
  };

  let logOut = async () => {
    await signOut(getAuth());
    setAppUser(undefined);
    setAuthUser(undefined);
  };

  let value = {
    authUser,
    appUser,
    logIn,
    logOut,
    isAuthed: appUser !== undefined,
    isLoading: isLoadingInitialAuth,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
