import { Experience } from "@iwaandr/iwaandr-sdk";
import axios from "axios";
import { ExperienceWithId } from "contexts/qa/experience/context";
import { endpoint } from "env/env_interop";
import { User } from "firebase/auth";
import { getAuthHeader } from "utils/get_auther_header";

export class PrivateExperienceQaApi {
  static async getExperiencesToQA(user: User): Promise<ExperienceWithId[]> {
    const resp = await axios.get<ExperienceWithId[]>(
      `${endpoint}/internal/admin/qa/experience`,
      {
        headers: {
          ...((await getAuthHeader(user)) ?? {}),
        },
      }
    );

    return resp.data;
  }

  static async approveExperience(
    user: User,
    experienceId: string
  ): Promise<Experience | string> {
    const value = await axios
      .post<{ experience: Experience }>(
        `${endpoint}/internal/admin/content/experience/approve`,
        { experienceId },
        {
          headers: {
            ...(await getAuthHeader(user)),
          },
        }
      )
      .then((resp) => resp.data?.experience ?? "Unknown response type.")
      .catch(() => "Failed to approve experience. ");

    return value;
  }

  static async createExperienceQaComment(
    adminReview: string,
    authUser: User,
    experienceId: string,
    reviewerId: string
  ) {
    const resp = axios.post(
      `${endpoint}/internal/admin/qa/comment/experience`,
      {
        adminReview,
        experienceId,
        reviewerId,
      },
      {
        headers: {
          ...(await getAuthHeader(authUser)),
        },
      }
    );
    return resp;
  }
}
