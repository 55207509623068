import {
  Button,
  Tooltip,
  Dialog,
  DialogContent,
  Grid,
  DialogActions,
  IconButton,
  DialogTitle,
  Alert,
  AlertTitle,
} from "@mui/material";
import React, { useState } from "react";
import useAuth from "contexts/auth/hooks";
import { AppStateExperience, AtlasFirebaseSchema } from "@iwaandr/iwaandr-sdk";
import { PrivateAtlasQaApi } from "features/qa/atlas/private";
import { PrivateExperienceQaApi } from "features/qa/experience/private";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { GreyLabelTextfield } from "../../form/text_inputs/grey-label-text-field";

interface ModalProps {
  /**
   * Atlas or experience that is being viewed
   */
  entity: AtlasFirebaseSchema | AppStateExperience;
  /**
   * toggles the modal
   */
  toggleModal: boolean;
  /**
   * set toggle modal state
   */
  setToggleModal: React.Dispatch<React.SetStateAction<boolean>>;
  /**
   * atlas id
   */
  authorEmail?: string | null;
}

const RequestChanges: React.FC<ModalProps> = (props: ModalProps) => {
  const { toggleModal, setToggleModal, authorEmail, entity } = props;
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const { authUser } = useAuth();

  function isAnExperience(
    entity: AtlasFirebaseSchema | AppStateExperience
  ): entity is AppStateExperience {
    return "picture" in entity;
  }

  return (
    <Dialog open={toggleModal}>
      <DialogActions>
        <Tooltip title="Close" aria-label="Close" arrow>
          <IconButton
            onClick={() => {
              setToggleModal(false);
            }}
          >
            <HighlightOffIcon
              sx={{
                height: { md: 35, xs: 28 },
                width: { md: 35, xs: 28 },
              }}
            />
          </IconButton>
        </Tooltip>
      </DialogActions>
      <DialogTitle>Enter requested changes</DialogTitle>
      <DialogContent sx={{ padding: { md: 5, sm: 5 } }}>
        <form>
          <GreyLabelTextfield
            sx={{
              width: {
                md: 500,
                sm: 300,
              },
            }}
            multiline={true}
            rows={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setMessage(e.target.value)
            }
          />
          <Grid sx={{ display: "flex", justifyContent: "right", margin: 1 }}>
            <Button
              sx={{
                height: { md: 30, xs: 5 },
                width: { md: 30, xs: 5 },
              }}
              color="primary"
              variant="contained"
              size="large"
              disabled={message.length <= 15}
              onClick={async () => {
                if (
                  !authUser ||
                  !entity ||
                  !entity.title ||
                  !authorEmail ||
                  !entity.id
                ) {
                  setErrorMessage(true);
                  return;
                }

                if (isAnExperience(entity)) {
                  PrivateExperienceQaApi.createExperienceQaComment(
                    message,
                    authUser,
                    entity.id,
                    authUser.uid
                  ).then((res) => {
                    if (res.data.error) {
                      setErrorMessage(true);
                    } else if (res.data.message) {
                      PrivateAtlasQaApi.requestChangesEmail(
                        authorEmail,
                        authUser,
                        message,
                        entity.title,
                        "experience"
                      );
                      setToggleModal(false);
                    }
                  });
                } else {
                  PrivateAtlasQaApi.createAtlasQaComment(
                    message,
                    authUser,
                    entity.id,
                    authUser.uid
                  ).then(async (res) => {
                    if (await res.data.error) {
                      setErrorMessage(true);
                    } else if (await res.data.message) {
                      PrivateAtlasQaApi.requestChangesEmail(
                        authorEmail,
                        authUser,
                        message,
                        entity.title,
                        "atlas"
                      );
                      setToggleModal(false);
                    }
                  });
                }
              }}
            >
              Send
            </Button>
          </Grid>
        </form>
      </DialogContent>
      {errorMessage ? (
        <Alert severity="error" onClose={() => setErrorMessage(false)}>
          <AlertTitle>Error!</AlertTitle>
          Problem creating QA comment!
        </Alert>
      ) : null}
    </Dialog>
  );
};
export default RequestChanges;
