import { IWaandrUser, RawExperienceLocation } from "@iwaandr/iwaandr-sdk";
import {
  Alert,
  Autocomplete as MuiAutocomplete,
  Avatar,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import useUserTool from "contexts/tools/users/hooks";
import { PublicProfileApi } from "features/account/api/public";
import * as React from "react";
import AutoComplete from "react-google-autocomplete";

const MuiGoogleAutocomplete = styled(AutoComplete)({
  padding: "16.5px 14px",
  fontSize: "16px",
  width: "100%",
});

const StyledCheckbox = styled(Checkbox)({
  color: "black",
});

interface AdminPostProps {}

const AdminPost: React.FunctionComponent<AdminPostProps> = () => {
  const [globalError, setGlobalError] = React.useState<undefined | string>();
  const { users, getUsers, error, isPolling } = useUserTool();
  const [
    inProgressCurrentUserEmail,
    setInProgressCurrentUserEmail,
  ] = React.useState("");
  const [currentUserEmail, setCurrentUserEmail] = React.useState("");
  const [currentUser, setCurrentUser] = React.useState<
    IWaandrUser | undefined
  >();

  React.useEffect(() => {
    if (users === undefined && !isPolling && !error) {
      getUsers();
    }
  }, [users, isPolling, error]);

  React.useEffect(() => {
    if (currentUserEmail === "" && currentUser === undefined) return;

    if (currentUserEmail === "" && currentUser) {
      setCurrentUser(undefined);
      return;
    }

    const user = users?.find((user) => {
      return currentUserEmail === user.email;
    });

    if (user === undefined) return;
    if (currentUser?.email !== user.email) {
      PublicProfileApi.getPublicProfileInfo(user.id)
        .then((iwaandrUser) => {
          if (globalError) {
            setGlobalError(undefined);
          }
          setCurrentUser({ ...iwaandrUser, email: currentUserEmail });
        })
        .catch((error) =>
          setGlobalError(error.message ?? "Failed to fetch user profile")
        );
    }
  }, [currentUserEmail, users, currentUser]);

  const isCurrentUserValid =
    currentUser?.photoUrl && currentUser?.bio && currentUser?.userName;

  const renderUser = () => {
    if (currentUser === undefined) {
      return <Typography variant="h6">Select a user to begin</Typography>;
    } else {
      return (
        <Grid item xs={6}>
          <Paper sx={{ padding: 3 }}>
            <Grid container>
              <Grid item xs={2}>
                <Avatar
                  src={currentUser.photoUrl}
                  sx={{ height: 50, width: 50 }}
                />
              </Grid>
              <Grid item xs={9} container>
                <Grid item xs={12}>
                  <Typography variant="h6">{currentUser?.userName}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>{currentUser?.bio}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={1} container alignContent="center">
                <Typography variant="h5">
                  {isCurrentUserValid ? "✅" : "❌"}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      );
    }
  };

  const renderForm = () => {
    if (currentUser === undefined) return null;

    if (!isCurrentUserValid) {
      return (
        <Grid item xs={12} container>
          <Typography variant="h5" textAlign="center">
            The current user cannot have content posted because their profile is
            not complete.
          </Typography>
        </Grid>
      );
    }

    return (
      <Grid item xs={12} container justifyContent="center" mb={4}>
        <form>
          <Grid container rowSpacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Image URL"
                placeholder="Image URL"
                variant="outlined"
                id="image-url"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Title" placeholder="Title" required fullWidth />
            </Grid>

            <Grid item xs={12} container>
              <Grid item xs={6} container>
                <Grid item xs={12}>
                  <FormControl>
                    <FormLabel
                      sx={{
                        color: "black",
                      }}
                    >
                      I am a...
                    </FormLabel>
                    <RadioGroup
                      color="primary"
                      //   value={value}
                      //   onChange={handleChange}
                    >
                      <FormControlLabel
                        value="local"
                        control={
                          <Radio
                            sx={{
                              color: "black",
                            }}
                          />
                        }
                        label="Local"
                      />
                      <FormControlLabel
                        value="visitor"
                        control={
                          <Radio
                            sx={{
                              color: "black",
                            }}
                          />
                        }
                        label="Visitor"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={6} container>
                <Grid item xs={12}>
                  <FormControl>
                    <FormLabel
                      sx={{
                        color: "black",
                      }}
                    >
                      Experience Type
                    </FormLabel>
                    <RadioGroup color="primary">
                      <FormControlLabel
                        value="atlas"
                        control={
                          <Radio
                            sx={{
                              color: "black",
                            }}
                          />
                        }
                        label="Atlas"
                      />
                      <FormControlLabel
                        value="single"
                        control={
                          <Radio
                            sx={{
                              color: "black",
                            }}
                          />
                        }
                        label="Single"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <MuiGoogleAutocomplete
                apiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY!}
                onPlaceSelected={(place) => {
                  console.log(place);
                  const formattedLocation = getLocationAddress(place);
                  console.log(formattedLocation);
                }}
                options={{
                  types: ["geocode", "establishment"],
                  fields: [
                    "address_components",
                    "geometry.location",
                    "formatted_address",
                    "name",
                  ],
                }}
              />
            </Grid>

            <Grid item xs={12} container rowSpacing={2}>
              <Grid item xs={12}>
                <Typography color="grey.400">Overall</Typography>
              </Grid>

              <Grid item xs={12}>
                <Slider
                  marks
                  min={0}
                  max={5}
                  step={0.5}
                  valueLabelDisplay="auto"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container rowSpacing={2}>
              <Grid item xs={12}>
                <Typography color="grey.400">Unique</Typography>
              </Grid>

              <Grid item xs={12}>
                <Slider
                  sx={{ color: (theme) => theme.palette.pink.main }}
                  marks
                  min={0}
                  max={5}
                  step={0.5}
                  valueLabelDisplay="auto"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container rowSpacing={2}>
              <Grid item xs={12}>
                <Typography color="grey.400">Enjoyable</Typography>
              </Grid>

              <Grid item xs={12}>
                <Slider
                  sx={{ color: (theme) => theme.palette.yellow.main }}
                  marks
                  min={0}
                  max={5}
                  step={0.5}
                  valueLabelDisplay="auto"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container rowSpacing={2}>
              <Grid item xs={12}>
                <Typography color="grey.400">Enjoyable</Typography>
              </Grid>

              <Grid item xs={12}>
                <Slider
                  sx={{ color: (theme) => theme.palette.yellow.main }}
                  marks
                  min={0}
                  max={5}
                  step={0.5}
                  valueLabelDisplay="auto"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container rowSpacing={2}>
              <Grid item xs={12}>
                <Typography color="grey.400">Good Value</Typography>
              </Grid>

              <Grid item xs={12}>
                <Slider
                  sx={{ color: (theme) => theme.palette.green.main }}
                  marks
                  min={0}
                  max={5}
                  step={0.5}
                  valueLabelDisplay="auto"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container rowSpacing={2}>
              <Grid item xs={12}>
                <Typography color="grey.400">Safe</Typography>
              </Grid>

              <Grid item xs={12}>
                <Slider
                  sx={{ color: (theme) => theme.palette.blue.main }}
                  marks
                  min={0}
                  max={5}
                  step={0.5}
                  valueLabelDisplay="auto"
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <hr />
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={4}>
                <FormControl>
                  <FormLabel
                    sx={{
                      color: "black",
                    }}
                  >
                    Price
                  </FormLabel>
                  <RadioGroup
                    color="primary"
                    //   value={value}
                    //   onChange={handleChange}
                  >
                    <FormControlLabel
                      value="free"
                      control={
                        <Radio
                          sx={{
                            color: "black",
                          }}
                        />
                      }
                      label="Free"
                    />
                    <FormControlLabel
                      value="$1 - 10"
                      control={
                        <Radio
                          sx={{
                            color: "black",
                          }}
                        />
                      }
                      label="$1 - 10"
                    />
                    <FormControlLabel
                      value="$11 - 25"
                      control={
                        <Radio
                          sx={{
                            color: "black",
                          }}
                        />
                      }
                      label="$11 - 25"
                    />
                    <FormControlLabel
                      value="$26 - 50"
                      control={
                        <Radio
                          sx={{
                            color: "black",
                          }}
                        />
                      }
                      label="$26 - 50"
                    />
                    <FormControlLabel
                      value="$51 - 100"
                      control={
                        <Radio
                          sx={{
                            color: "black",
                          }}
                        />
                      }
                      label="$51 - 100"
                    />
                    <FormControlLabel
                      value="$100+"
                      control={
                        <Radio
                          sx={{
                            color: "black",
                          }}
                        />
                      }
                      label="$100+"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl>
                  <FormLabel
                    sx={{
                      color: "black",
                    }}
                  >
                    Time
                  </FormLabel>
                  <RadioGroup
                    color="primary"
                    //   value={value}
                    //   onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Anytime"
                      control={
                        <Radio
                          sx={{
                            color: "black",
                          }}
                        />
                      }
                      label="Anytime"
                    />
                    <FormControlLabel
                      value="0-30 min"
                      control={
                        <Radio
                          sx={{
                            color: "black",
                          }}
                        />
                      }
                      label="0-30 min"
                    />
                    <FormControlLabel
                      value="31-60 min"
                      control={
                        <Radio
                          sx={{
                            color: "black",
                          }}
                        />
                      }
                      label="31-60 min"
                    />
                    <FormControlLabel
                      value="1-2 hrs"
                      control={
                        <Radio
                          sx={{
                            color: "black",
                          }}
                        />
                      }
                      label="1-2 hrs"
                    />
                    <FormControlLabel
                      value="2-4 hrs"
                      control={
                        <Radio
                          sx={{
                            color: "black",
                          }}
                        />
                      }
                      label="2-4 hrs"
                    />
                    <FormControlLabel
                      value="4+ hrs"
                      control={
                        <Radio
                          sx={{
                            color: "black",
                          }}
                        />
                      }
                      label="4+ hrs"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={4} container>
                <Grid item xs={12}>
                  <Typography>Catogories</Typography>
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={<StyledCheckbox />}
                    label="Culture"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={<StyledCheckbox />}
                    label="Foodies"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={<StyledCheckbox />}
                    label="Views"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={<StyledCheckbox />}
                    label="Outdoors"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={<StyledCheckbox />}
                    label="Chill"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={<StyledCheckbox />}
                    label="Hidden Gems"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={<StyledCheckbox />}
                    label="Active"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={<StyledCheckbox />}
                    label="Adventure"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={<StyledCheckbox />}
                    label="Historical"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={<StyledCheckbox />}
                    label="Thrill"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={<StyledCheckbox />}
                    label="Interactive"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={<StyledCheckbox />}
                    label="Artsy"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <hr />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="What you can do"
                placeholder="What you can do"
                multiline
                rows={3}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Activity-specific details"
                placeholder="Activity-specific details"
                multiline
                rows={3}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Customs & Conservation"
                placeholder="Customs & Conservation"
                multiline
                rows={3}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Best route"
                placeholder="Best route"
                multiline
                rows={3}
                fullWidth
              />
            </Grid>
          </Grid>
        </form>
      </Grid>
    );
  };

  return (
    <Grid container height="100%" justifyContent="center">
      <Grid container item xs={9} mt={4}>
        {error || globalError ? (
          <Grid item xs={12} container justifyContent="center" mb={2}>
            <Grid item xs={9}>
              <Alert severity="error">{error ?? globalError}</Alert>
            </Grid>
          </Grid>
        ) : null}

        <Grid item xs={12} container justifyContent="center" sx={{ mb: 5 }}>
          <Grid item>
            <Typography variant="h4" textAlign="center">
              Oh, hi! You found a feature still in development 🎉
            </Typography>
            <Typography color="error" textAlign="center" paragraph>
              Unfortunately, this feature is not ready for use yet.
            </Typography>
            <Typography textAlign="center" paragraph>
              So why is it here? Because it established a few patterns. Using
              environment variables, introducing google locations into the app,
              querying for users, etc. We could have kept the codebase a little
              cleaner by ripping out everything that wasn't relatively useful,
              but this was easier and faster for now. We'll finish this feature
              when we need to!
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <Grid item>
            <Typography variant="h3" textAlign="center">
              Admin Post
            </Typography>
            <Typography>
              This feature allows you to post content on behalf of a user that
              you are not.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} container mt={5}>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            sx={{ minHeight: 150 }}
          >
            {renderUser()}
          </Grid>

          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            sx={{ minHeight: 150 }}
          >
            <Grid item xs={6}>
              <MuiAutocomplete
                value={inProgressCurrentUserEmail}
                onChange={(_, val) => {
                  setInProgressCurrentUserEmail(val ?? "");
                }}
                onSelect={(e) => {
                  if (inProgressCurrentUserEmail !== currentUserEmail) {
                    setCurrentUserEmail(inProgressCurrentUserEmail);
                  }
                }}
                options={users?.map((user) => user.email) ?? []}
                loading={users === undefined}
                renderInput={(params) => <TextField {...params} label="User" />}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        {renderForm()}
      </Grid>
    </Grid>
  );
};

export function getLocationAddress(
  placeResult: google.maps.places.PlaceResult
) {
  if (!placeResult || Object.keys(placeResult).length === 0) return undefined;

  const locationObj: RawExperienceLocation = {
    locationDescription: placeResult.name,
    lat: placeResult.geometry?.location?.lat(),
    long: placeResult.geometry?.location?.lng(),
  };

  placeResult.address_components?.forEach((component) => {
    const types = component.types;

    // these options should give us backups for strict addresses on the city level
    if (types.includes("locality")) {
      locationObj.city = component.long_name;
    } else if (
      types.includes("administrative_area_level_2") &&
      !locationObj.city
    ) {
      locationObj.city = component.long_name;
    } else if (types.includes("establishment") && !locationObj.city) {
      locationObj.city = component.long_name;
    } else if (types.includes("administrative_area_level_1")) {
      locationObj.adminRegion = component.long_name;
    } else if (types.includes("country")) {
      locationObj.country = component.long_name;
    } else if (types.includes("postal_code")) {
      locationObj.postalCode = component.long_name;
    }
  });

  return locationObj;
}

export default AdminPost;
