import { IWaandrUser } from "@iwaandr/iwaandr-sdk";
import axios from "axios";
import { endpoint } from "env/env_interop";

export class PublicProfileApi {
  /** Fetches the profile data for the user attached the user ID */
  static async getPublicProfileInfo(id: string): Promise<IWaandrUser> {
    const resp = await axios
      .get<IWaandrUser>(`${endpoint}/account_public/get-user-data`, {
        params: {
          userId: id,
        },
      })
      .then((resp) => resp.data)
      .catch(() => {
        throw Error("Could not retrieve user.");
      });

    return resp;
  }
}
