import * as React from "react";
import { Grid, Card, Typography, CardContent } from "@mui/material";
import { useNavigate } from "react-router-dom";

function ViewReports() {
  const navigate = useNavigate();
  const generateMenuCard = (title: string, subText: string, path: string) => {
    return (
      <Card
        sx={{ minWidth: 275, cursor: "pointer" }}
        onClick={() => navigate(path)}
      >
        <CardContent>
          <Typography textAlign={"center"} variant={"h5"} sx={{ fontSize: 14 }}>
            {title}
          </Typography>
          <Typography textAlign={"center"} sx={{ fontSize: 14 }} gutterBottom>
            {subText}
          </Typography>
        </CardContent>
      </Card>
    );
  };

  return (
    <Grid
      container
      spacing={3}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Grid item xs={12} textAlign="center">
        <Grid item xs={12} textAlign="center">
          <Typography variant={"h4"} style={{ marginTop: 20 }}>
            Welcome to the Reports!
          </Typography>
        </Grid>
        <Typography>
          Below are current reports that are available. Click on one to navigate
          to the screen!
        </Typography>
      </Grid>
      <Grid item xs={4}>
        {generateMenuCard(
          "Content Reports",
          "Shows all resolved and unresolved content reports.",
          "/app/reports/content"
        )}
      </Grid>
      <Grid item xs={4}>
        {generateMenuCard(
          "User Reports",
          "Shows all resolved and unresolved user reports.",
          "/app/reports/user"
        )}
      </Grid>
      <Grid item xs={4}>
        {generateMenuCard(
          "User Report Profiles",
          "Shows all users and reports connected to them.",
          "/app/reports/profile"
        )}
      </Grid>
    </Grid>
  );
}

export default ViewReports;
