import { AtlasFirebaseSchema } from "@iwaandr/iwaandr-sdk";
import useAuth from "contexts/auth/hooks";
import { PrivateToolUserApi } from "features/tools/users/api/private";

import * as React from "react";

export interface SimpleUserRepresentation {
  email: string;
  id: string;
}

interface UserToolContextType {
  users: SimpleUserRepresentation[] | undefined;
  getUsers(): Promise<void>;
  isPolling: boolean;
  error: string | undefined;
}

export const UserToolContext = React.createContext<UserToolContextType>(null!);

export function UserToolContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  let [users, setUsers] = React.useState<
    SimpleUserRepresentation[] | undefined
  >();
  let { authUser } = useAuth();
  let [isPolling, setIsPolling] = React.useState(false);
  let [error, setError] = React.useState<undefined | string>();

  let getUsers = async (): Promise<void> => {
    if (!authUser) return;
    setIsPolling(true);
    PrivateToolUserApi.getUsers(authUser)
      .then((data) => {
        setUsers(data);
        setIsPolling(false);
      })
      .catch(() => {
        setError("There was an error fetching the users.");
        setIsPolling(false);
      });
  };

  return (
    <UserToolContext.Provider
      value={{
        getUsers,
        users: users,
        isPolling,
        error,
      }}
    >
      {children}
    </UserToolContext.Provider>
  );
}
