import { ReportedUserEntityView } from "@iwaandr/iwaandr-sdk";
import {
  Avatar,
  Box,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { createStyles, withStyles, WithStyles } from "@mui/styles";
import useAuth from "contexts/auth/hooks";
import { PrivateReportApi } from "features/report/api/private";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import theme from "theme";

const styles = () =>
  createStyles({
    gridContainer: {
      marginTop: 20,
    },
    padding15: {
      padding: 15,
    },
    image: {
      borderRadius: 10,
    },
  });

interface UserReportView extends WithStyles<typeof styles> {}

const UserReportView: React.FC<UserReportView> = ({ classes }) => {
  const params = useParams();
  const { authUser } = useAuth();
  const reportId = params.reportId;

  const [report, setReport] = useState<ReportedUserEntityView>();

  useEffect(() => {
    if (!authUser || !reportId) return;

    PrivateReportApi.getUserReport(authUser, reportId).then((report) => {
      setReport(report);
    });
  }, []);

  if (!report) return null;

  const date = new Date(report.date).toDateString();
  const status = report.resolved ? "Resolved" : "Unresolved";

  return (
    <Grid container display={"flex"} className={classes.gridContainer}>
      <Grid item xs={12} justifyContent="center">
        <Box mb={2}>
          <Typography variant={"h6"}>
            Status:{" "}
            <Typography
              component={"span"}
              variant={"h6"}
              color={
                report.resolved
                  ? theme.palette.success.main
                  : theme.palette.error.main
              }
            >
              <u>{status}</u>
            </Typography>
          </Typography>
        </Box>

        <Paper className={classes.padding15}>
          <Stack spacing={1}>
            <Typography variant={"h6"}>Report Details</Typography>
            <Typography>
              <b>Report Id:</b> {report.reportId}
            </Typography>
            <Typography>
              <b>Reason:</b> {report?.reason}
            </Typography>
            <Typography>
              <b>Other Concerns:</b> {report?.otherConcerns}
            </Typography>
            <Typography>
              <b>Date Reported:</b> {date}
            </Typography>

            <Divider />

            <Typography variant={"h6"}>Reporter Details</Typography>
            <Stack
              direction={"row"}
              spacing={3}
              justifyContent={"space-between"}
            >
              <Stack spacing={1}>
                <Typography>
                  <b>Reporter Id:</b> {report.reporterInfo.userId}
                </Typography>
                <Typography>
                  <b>Name:</b> {report.reporterInfo.userName}
                </Typography>
                <Typography>
                  <b>Bio:</b> {report.reporterInfo.bio}
                </Typography>
              </Stack>
              <Avatar
                src={report.reporterInfo.photoUrl}
                sx={{ height: "70px", width: "70px" }}
              />
            </Stack>

            <Divider />

            <Typography variant={"h6"}>Reportee Details</Typography>
            <Stack
              direction={"row"}
              spacing={3}
              justifyContent={"space-between"}
            >
              <Stack spacing={1}>
                <Typography>
                  <b>Reportee Id:</b> {report.reporteeInfo.userId}
                </Typography>
                <Typography>
                  <b>Name:</b> {report.reporteeInfo.userName}
                </Typography>
                <Typography>
                  <b>Bio:</b> {report.reporteeInfo.bio}
                </Typography>
              </Stack>
              <Avatar
                src={report.reporteeInfo.photoUrl}
                sx={{ height: "70px", width: "70px" }}
              />
            </Stack>
          </Stack>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(UserReportView);
