import { TextField, TextFieldProps, Theme } from "@mui/material";
import { styled } from "@mui/system";

type TextField = TextFieldProps;

export const GreyLabelTextfield = styled(TextField)(({ theme }) => ({
  ".MuiInputLabel-root": {
    color: theme.palette.grey[400],
  },
}));
